import React from 'react';
import TodoItem from '../TodoItem';

const WebsiteList = props => {
  // const todos = props.todos || {};

  return (
    <div className="subList" id="website">
      <h2 className="title">Website</h2>
      <p className="subtitle">Establish a presence on the internet</p>
      <div className="items">
        <TodoItem id="website-domain">
          <p className="title">Select a domain name for your website</p>
          <div className="description">
            Use a tool like{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://domains.google">
              Google Domains
            </a>{' '}
            to check availability and purchase your domain. If you use Google Domains, it'll be very
            easy to set up email as well (more below).
          </div>
        </TodoItem>
        <TodoItem id="website-designer">
          <p className="title">Make a website for your practice</p>
          <div className="description">
            There are a lot of ways to get a website up and running.
            <br />
            <br />
            <ul>
              <li>
                Build your website yourself using a website builder service like{' '}
                <a target="_blank" rel="noopener noreferrer" href="https://www.wix.com">
                  Wix
                </a>
                ,{' '}
                <a target="_blank" rel="noopener noreferrer" href="https://wordpress.com">
                  WordPress
                </a>
                , or{' '}
                <a target="_blank" rel="noopener noreferrer" href="https://squarespace.com">
                  Squarespace
                </a>
                . These charge a monthly fee ($10-20) and let you design your site with a
                drag-and-drop interface. Personally, I recommend Squarespace; it has a very clean
                interface and easy to make a beautiful site.
              </li>
              <li>
                Hire a freelance programmer using{' '}
                <a target="_blank" rel="noopener noreferrer" href="https://www.upwork.com/">
                  Upwork
                </a>{' '}
                or{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.freelancer.com">
                  Freelancer
                </a>
                . Typical cost is $500-1000 dollars.
              </li>
              <li>
                You could also solicit a web design studio, which will cost more then a freelancer
                but deliver a well-designed and polished product. This will usually exceed a
                thousand dollars, but gets cheaper if you find a studio who has made similar
                websites in the past (like{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.peachyhillcreative.com/">
                  Peachy Hill
                </a>
                ).
              </li>
              <li>
                Use {' '}
                <a target="_blank" rel="noopener noreferrer" href="https://dpcspot.com/">
                  DPC Spot
                </a>. DPC Spot is probably the easiest to set up a website for your practice, since their templates already contain a lot of DPC-tailored content. Check out a sample site <a target="_blank" rel="noopener noreferrer" href="https://dpcspotweb.firebaseapp.com/">here</a>.
              </li>
            </ul>
          </div>
        </TodoItem>
        <TodoItem id="website-email">
          <p className="title">Set up email hosting</p>
          <div className="description">
            <p>
              This will let you receive email at an address with your new domain. Usually you can do
              this through whatever service you used to purchase the domain in the first place.
            </p>
            <p>
              Google offers a business offering called GSuite that includes email hosting. The price
              is $5/user/month, which gets you email hosting in addition to a bunch of other Google
              services like Calendar, Docs, and Drive. Plus, Google will sign a BAA, so you can have
              HIPAA-compliant email thorugh a familiar Gmail interface.
            </p>
          </div>
        </TodoItem>
      </div>
    </div>
  );
};

export default WebsiteList;
