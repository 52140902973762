import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { message, Button, Anchor, Icon, Input } from 'antd';

// import gql from 'graphql-tag';
import * as api from '../api';
import store from '../store';
import {
  pdfModalDuck
  // trelloModalDuck
} from '../ducks/root';
import * as globals from '../globals';
// import apollo from '../apollo';
import * as authUtil from '../auth';
import * as sassVars from '../style/_vars.scss';
// import LoginWrapper from './LoginWrapper';
// import * as util from '../util';

import LearnList from './lists/LearnList.js';
import ValidationList from './lists/ValidationList.js';
import PracticeList from './lists/PracticeList.js';
import ScopeList from './lists/ScopeList.js';
import PricingList from './lists/PricingList.js';
import IncorporationList from './lists/IncorporationList.js';
import BusinessList from './lists/BusinessList.js';
import AdministrativeList from './lists/AdministrativeList.jsx';
import OptoutList from './lists/OptoutList.js';
import LegalList from './lists/LegalList.js';
import OfficeList from './lists/OfficeList.js';
import VendorsList from './lists/VendorsList.js';
import WebsiteList from './lists/WebsiteList.js';
import SoftwareList from './lists/SoftwareList.js';
import InsuranceList from './lists/InsuranceList.js';
import CliaList from './lists/CliaList.js';
import HipaaList from './lists/HipaaList.js';
import OshaList from './lists/OshaList.js';
import MarketingList from './lists/MarketingList.js';
import WaitlistInput from './WaitlistInput';

const { Search } = Input;
const { Link } = Anchor;

const sublists = [
  { id: 'learn', title: 'Learn', component: LearnList },
  { id: 'validation', title: 'Viability', component: ValidationList },
  { id: 'practice', title: 'Initial Decisions', component: PracticeList },
  { id: 'pricing', title: 'Pricing', component: PricingList },
  { id: 'scope', title: 'Scope of Practice', component: ScopeList },
  { id: 'incorporation', title: 'Incorporation', component: IncorporationList },
  { id: 'legal', title: 'Forms', component: LegalList },
  { id: 'business', title: 'Business Plan', component: BusinessList },
  { id: 'admin', title: 'Administrative', component: AdministrativeList },
  { id: 'office', title: 'Office', component: OfficeList },
  { id: 'optout', title: 'Opt-Out', component: OptoutList },
  { id: 'vendors', title: 'Vendors', component: VendorsList },
  { id: 'website', title: 'Website', component: WebsiteList },
  { id: 'software', title: 'Software', component: SoftwareList },
  { id: 'insurance', title: 'Insurance', component: InsuranceList },
  { id: 'clia', title: 'CLIA', component: CliaList },
  { id: 'hipaa', title: 'HIPAA', component: HipaaList },
  { id: 'osha', title: 'OSHA', component: OshaList },
  { id: 'marketing', title: 'Marketing', component: MarketingList }
];

class TodoList extends React.Component {
  state = {
    email: '',
    pending: false
  };
  componentDidMount() {
    // TODO: fetch all todoes, write to redux
    // authUtil.renewTokens();
    // setInterval(authUtil.renewTokens, 60000);
    // this.getTodos();
    // setTimeout(this.setLinks, 1000);
    // this.setLinks();
    if (this.props.print) {
      document.querySelectorAll('a').forEach(a => {
        let href = a.getAttribute('href');
        if (href[0] === '/') href = `https://bagel.md${href}`;
        if (href[href.length - 1] === '/') href = href.slice(0, -1);

        var link = document.createElement('a');
        var text = document.createTextNode(` (${href})`);
        link.appendChild(text);
        link.href = href;
        // a.text = `${a.text} (${href})`;
        a.insertAdjacentElement('afterend', link);
      });
    }
  }

  setLinks = async () => {
    const links = document.querySelectorAll('a');
    for (let a of links) {
      console.log(a);
      let href = a.getAttribute('href');
      if (href[0] === '/') href = `https://bagel.md${href}`;
      // if (href[href.length - 1] === '/') href = href.slice(0, -1);
      a.text = `${a.text} (${href})`;

      const result = await axios({
        method: 'GET',
        url: `http://tinyurl.com/api-create.php?url=${href}`
      });
      console.log(result.data);
    }
  };

  render() {
    const lists = sublists.map(subl => {
      if (this.props.id && this.props.id !== subl.id) return null;
      let Comp = subl.component;
      return <Comp key={subl.id} />;
    });

    let onWaitlist = false;
    if (typeof window !== 'undefined') {
      onWaitlist = window.localStorage.getItem('onWaitlist') === 'true';
    }
    if (this.props.print) {
      return (
        <div className="fill">
          <div className="page col ac">
            <h1
              className="wide tac pagetitle"
              style={{ margin: '300px 0px 0px 0px', textAlign: 'center', fontSize: '30pt' }}>
              The DPC Startup Handbook
            </h1>
            <hr style={{ color: 'black', width: '59%' }} />
            <p className="readable" style={{ fontSize: '16pt', margin: '15px 0px 15px 0px' }}>
              A comprehensive guide to starting a DPC practice
            </p>
            <br />
            <br />
            <br />
            <p>a resource from</p>
            <img style={{ width: '150px' }} src="/img/gradient_wordmark.png" />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <p style={{ fontSize: '12pt' }}>view this handbook online at</p>
            <p>https://bagel.md/todo</p>
          </div>
          <div className="page" style={{ visibility: 'hidden' }}>
            break
          </div>
          <div className="page col ac">
            <h1
              className="wide tac pagetitle"
              style={{ margin: '100px 0px 40px 0px', textAlign: 'center' }}>
              The DPC Startup Handbook
            </h1>
            <p>a resource from</p>
            <img style={{ width: '150px' }} src="/img/gradient_wordmark.png" />
            <p
              className="readable tac fl1"
              style={{ margin: '10px 0px', fontSize: '12pt', maxWidth: '375px' }}>
              Bagel is a new all-in-one software for DPC coming in August 2019. If you're planning
              to start a DPC practice, join the waitlist at https://bagel.md to stay updated and get
              early access to the beta version when it's ready.
            </p>
            <br />
            <br />
            <br />
            <br />
            <p className="readable tac" style={{ opacity: 0.7 }}>
              {this.props.desc ||
                `Our aim in writing this handbook was to create the most comprehensive single resource on starting a DPC practice. It walks you though the process of starting your practice step-by-step, with
        detailed instructions, links to other resources, plenty of sample documents, and practical
        advice gathered from dozens of sucessful practices.`}
            </p>
            <br />
            <br />
            <p className="readable tac" style={{ opacity: 0.7 }}>
              {this.props.desc ||
                `We hope this guide makes it as easy as possible to start a DPC practice. Unfortunately, "as easy as possible" is still pretty darn hard. If you hit any roadblocks or have any questions about something in this guide, email us at startup@bagel.md! We're happy to help.`}
            </p>
            <br />
            <br />
            <p className="readable tac" style={{ opacity: 0.7 }}>
              View the online version of this handbook at https://bagel.md/todo.
            </p>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <p
              className="readable tac fl1"
              style={{ margin: '0px', fontSize: '11pt', maxWidth: '300px' }}>
              © 2019 Bagel Health, Inc.
            </p>
          </div>
          <div className="page" style={{ visibility: 'hidden' }}>
            break
          </div>
          {lists}
          <div className="page" style={{ visibility: 'hidden' }}>
            break
          </div>
          <div className="page col ac jc tac">
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <p>a resource from</p>
            <img style={{ width: '150px' }} src="/img/gradient_wordmark.png" />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <p>
              Learn more about Bagel at
              <br />
              https://bagel.md
            </p>
          </div>
        </div>
      );
    }

    if (this.props.pdf) {
      return (
        <div className="fill">
          <div className="page col ac">
            <h1
              className="wide tac pagetitle"
              style={{ margin: '300px 0px 0px 0px', textAlign: 'center', fontSize: '30pt' }}>
              The DPC Startup Handbook
            </h1>
            <hr style={{ color: 'black', width: '59%' }} />
            <p className="readable" style={{ fontSize: '16pt', margin: '15px 0px 15px 0px' }}>
              A comprehensive guide to starting a DPC practice
            </p>
            <br />
            <br />
            <br />
            <p>a resource from</p>
            <img style={{ width: '150px' }} src="/img/gradient_wordmark.png" />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <p style={{ fontSize: '12pt' }}>view this handbook online at</p>
            <p>https://bagel.md/todo</p>
          </div>

          <div className="page" style={{ visibility: 'hidden' }}>
            break
          </div>
          {lists}
          <div className="page" style={{ visibility: 'hidden' }}>
            break
          </div>
        </div>
      );
    }

    return (
      <div className="section col ac jc" style={{ padding: '80px 4vw' }}>
        <br />
        <br />
        <Icon type="check-circle" style={{ fontSize: '40px', color: sassVars.blueColor }} />
        <br />
        <h1
          style={{
            lineHeight: '1.2',
            maxWidth: '800px',
            textAlign: 'center',
            fontSize: '37pt'
          }}>
          {this.props.title || 'The DPC Startup Handbook'}
        </h1>
        {false && globals.isLocal && <button onClick={this.getTodos}>Run GraphQL query</button>}
        <br />
        <p className="readable" style={{ textAlign: 'center' }}>
          {this.props.desc ||
            `This handbook is the most comprehensive single resource for any aspiring direct primary
        care physician. It walks you though the process of starting your practice step-by-step, with
        detailed instructions, links to other resources, plenty of sample documents, and practical
        advice gathered from dozens of successful DPC practices.`}
        </p>

        <br />
        <div className="row as jc wrap">
          {false && (
            <div className="col ac readable mw200 m16">
              <br />
              <p className="bold fs18 mb16 mt32" style={{ fontFamily: 'Signika' }}>
                Prefer paper?
              </p>
              <Search
                size="large"
                placeholder="doogie@howser.com"
                onChange={e => this.setState(e.target.value)}
                enterButton="Get PDF"
                onSearch={async () => {
                  if (this.state.pending) return;
                  this.setState({ pending: true });
                  message.loading('Sending email...', 0);
                  try {
                    console.log(this.state.email);
                    await api.post(['sendPDF'], { email: this.state.email });
                    // store.dispatch(pdfModalDuck.clear());
                    message.destroy();
                    message.info('Email sent!');
                  } catch (err) {
                    console.log('ERROR');
                    console.log(err);
                    // message.error(err.message);
                  }
                  this.setState({ pending: false, email: '' });
                }}
              />
              <p className="fs10 mt8 tac">Enter your email and we'll send you a PDF version.</p>
            </div>
          )}

          <div className="col ac readable mw200 m16">
            <p className="bold fs13 mb1">Prefer paper?</p>
            <Button
              icon="download"
              type="primary"
              onClick={() => {
                store.dispatch(pdfModalDuck.set(true));
              }}>
              Get PDF version
            </Button>
            <br />
            <br />
          </div>

          {/* <div className="col ac readable mw200 m16">
            <p className="bold fs13 mb4">
              Prefer Asana?{' '}
              <Tooltip title="Asana is an excellent task management tool that lets you track your progress, collaborate with others, upload files, delegate tasks, set due dates, and more. We strongly recommend using it to manage your DPC startup journey!">
                <Icon style={{ fontSize: '14px' }} type="question-circle" />
              </Tooltip>
            </p>
            <Button
              icon="team"
              type="primary"
              onClick={() => {
                store.dispatch(trelloModalDuck.set(true));
              }}>
              Request Invite
            </Button>
          </div> */}
        </div>
        <br />
        <br />
        <br />
        <br />

        {/* <div
          className="card col ac readable"
          style={{ backgroundColor: `#73080508`, borderColor: `#730805c0` }}>
          <br />
          <h5 className="mb8 mxw400 tac " style={{ lineHeight: 1 }}>
            Introducing Brick Health!
            <br />A new revenue stream for DPCs
          </h5>
          <p className="mb16">
            We just announced a joint venture with DPC Frontier called Brick Health. If you run a
            DPC practice or plan to start one soon, Brick Health can provide you with the additional
            revenue you need to pay the bills while you grow your panel!
          </p>
          <p className="mb16">For more details, check out the announcement post on DPC Frontier.</p>
          <Button
            type="primary"
            href="https://www.dpcfrontier.com/blog/2021/1/7/introducing-airpcp"
            target="_blank">
            Read the announcement
          </Button>
          <br />
        </div> */}

        {false && !onWaitlist && (
          <div className="card col ac readable">
            <h5 className="mb8">Bagel is coming soon</h5>
            <p className="mb16">{`Bagel is a new all-in-one software platform for direct primary care. We're about to launch an early version of the software, and we're looking for feedback. If you want to help shape the future of DPC software, request beta access below.`}</p>
            <WaitlistInput />
            <div className="mt8" />
            <p>
              <small>
                or join the{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/groups/bagelmd/">
                  Bagel Facebook group
                </a>{' '}
                to stay updated
              </small>
            </p>
          </div>
        )}

        <br />
        <br />
        <br />
        <br />
        <div className="row wide jc" id="listbody">
          {this.props.id === undefined && (
            <div className="fl1 gutter pl32">
              <Anchor offsetTop={100}>
                {sublists.map(subl => {
                  return <Link href={'#' + subl.id} key={subl.id} title={subl.title} />;
                })}
              </Anchor>
            </div>
          )}
          <div className="fl5 readable">{lists}</div>
          {this.props.id === undefined && <div className="fl1 gutter" />}
        </div>
        <div className="wide row jc ac">
          <small>The above content is not legal or medical advice.</small>
        </div>
      </div>
    );
  }
}

const mstp = state => ({
  idToken: state.idToken,
  accessToken: state.accessToken,
  expiresAt: state.expiresAt,
  loggedIn: authUtil.isAuthenticated(state.expiresAt)
});

export default connect(mstp)(TodoList);
