import React from 'react';

import Head from '../../components/Head';
import TodoSubList from '../../components/TodoSubList';

import '../../style/_common.scss';

class TodoPage extends React.Component {
  render() {
    const title = 'How to Set Pricing For Your DPC Practice';
    const desc = `Here are some considerations when determining the pricing model of your DPC practice`;
    return (
      <TodoSubList title={title} desc={desc} id="pricing">
        <Head title={title} description={desc} url="/todo/pricing/" image="/og_image.jpg" />
      </TodoSubList>
    );
  }
}

export default TodoPage;
