import React from 'react';
import TodoItem from '../TodoItem';

const PracticeList = props => {
  // const todos = props.todos || {};

  return (
    <div className="subList" id="practice">
      <h2 className="title">Initial Decisions</h2>
      <p className="subtitle">Make some initial decisions about your practice</p>
      <div className="items">
        <TodoItem id="practice-bizname">
          <p className="title">Pick a business name for your practice</p>
          <div className="description">
            <p>
              Note that your legal business name doesn't have to be related whatsoever to your
              public-facing marketing name! Choose a business name quickly and don't drag your feet.
              You want to incorporate as fast as possible so you can start engaging with vendors as
              a legal entity.
            </p>
            <p>Google around for your state's online tool for checking name availability.</p>
          </div>
        </TodoItem>
        <TodoItem id="practice-name">
          <p className="title">Pick a marketing name for your practice</p>
          <div className="description">
            <p>
              If you're very well known in the community, you may want to include your name in the
              practice name for marketing reasons. If you plan to hire additional physicians or take
              on partners at some point, you probably don't. Click around on the{' '}
              <a target="_blank" rel="noopener noreferrer" href="https://mapper.dpcfrontier.com">
                DPC Frontier Mapper
              </a>{' '}
              for ideas.
            </p>
            <p>
              Some other considerations: make sure there are memorable social media
              usernames/handles available. Use a service like{' '}
              <a target="_blank" rel="noopener noreferrer" href="https://namecheckr.com">
                namecheckr.com
              </a>{' '}
              to check availability for a name across all social media sites at once. You'll also
              want to make sure a decent domain name is available. Use{' '}
              <a target="_blank" rel="noopener noreferrer" href="https://domains.google.com">
                Google Domains
              </a>{' '}
              to check availability.
            </p>
          </div>
        </TodoItem>
        {false && (
          <TodoItem id="practice-who-to-accept">
            <p className="title">Consider who to accept as patients</p>
            <div className="description">
              Be decisive! Going back-and-forth on your practice name hold up many other aspects of
              planning your practice: incorporation, marketing, contract
            </div>
          </TodoItem>
        )}
        <TodoItem id="practice-hours">
          <p className="title">Decide on your business hours</p>
          <div className="description">
            Consider whether to have posted business hours at all. - if you only intend to be
            available on request, you may not want to commit to a pre-specified workday (though if
            you intend to have front-office staff, you probably should for their sake).{' '}
          </div>
        </TodoItem>
        <TodoItem id="practice-specialty">
          <p className="title">Decide whether to give your practice a specialist "flavor"</p>
          <div className="description">
            Some DPC practices have a bent towards a particular specialty/population, including
            pediatrics, geriatrics, sports medicine, PT, addiction medicine, pain management,
            endocrinology, wellness/nutrition, and more. Consider doing something similar if you're
            more specialized.
          </div>
        </TodoItem>
        <TodoItem id="practice-hybrid">
          <p className="title">Decide whether to run a "pure" or "hybrid" practice</p>
          <div className="description">
            There are many shades of "purity" among DPC practices.
            <br />
            <br />
            Some hybrid practices still accept insurance from a subset of their patients, or for
            certain non-included procedures. This is common for practices that are transitioning
            from a traditional practice to DPC. This discussion is laid out in more detail by DPC
            Frontier{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.dpcfrontier.com/pure-or-hybrid-practice/">
              here
            </a>
            . Also note that billing any insurance company unquestionably makes you a “covered
            entity” under HIPAA. A pure DPC practice{' '}
            <a target="_blank" rel="noopener noreferrer" href="http://www.dpcfrontier.com/hipaa/">
              may not be
            </a>
            .
            <br />
            <br />
            It is technically possible to run a DPC practice AND bill Medicare for certain
            procedures, if the benefits of practice membership explicity include only "non-covered
            services" (listed{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://go.cms.gov/2nSYsbL"
              // href="https://www.cms.gov/Outreach-and-Education/Medicare-Learning-Network-MLN/MLNProducts/Downloads/Items-and-Services-Not-Covered-Under-Medicare-Booklet-ICN906765.pdf"
            >
              here
            </a>
            ), though that is highly restrictive. See DPC Frontier's analysis{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.dpcfrontier.com/opting-out-of-medicare">
              here
            </a>
            .
            <br />
            <br />
            Also note that billing any insurance company unquestionably makes you a “covered entity”
            under HIPAA. A pure DPC practice{' '}
            <a target="_blank" rel="noopener noreferrer" href="http://www.dpcfrontier.com/hipaa/">
              may not be
            </a>
            .
          </div>
        </TodoItem>

        {false && (
          <TodoItem id="practice-in-house-dispensing">
            <p className="title">Decide whether to do in-house dispensing</p>
            <div className="description">
              In-house dispensing is a great perk to offer your patients: if saves them trips to the
              pharmacy and a lot of money besides. Some states also require licenses, others impose
              limitations, and other make it nearly impossible - see DPC Frontier's{' '}
              <a target="_blank" rel="noopener noreferrer" href="http://www.dpcfrontier.com/states">
                state-by-state legal analysis
              </a>{' '}
              for description.
              <br />
              <br />
              There may be additional administrative overheads associated with buying and tracking
              Rx inventory, but it doesn't have to be burdensome. You can purchase pre-packaged
              pharmaceuticals from wholesalers like{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.andameds.com/login.htm">
                AndaMeds
              </a>{' '}
              or{' '}
              <a target="_blank" rel="noopener noreferrer" href="https://www.henryschein.com">
                Henry Schein
              </a>
              , and re-distribute them to patients in the original packaging. If you prefer more
              fine grained control, you may need to purchase a pill-counter and Rx inventory
              tracking software.
            </div>
          </TodoItem>
        )}
      </div>
    </div>
  );
};

export default PracticeList;
