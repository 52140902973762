import React from 'react';
import { Helmet } from 'react-helmet';

const defaultTitle = 'The All-in-One Software for Direct Primary Care';
const defaultDescription = '';
const defaultURL = '/';
const defaultImage = '/og_image.jpg';

const Head = props => {
  let { title, description, url, image } = props;

  title = title || defaultTitle;
  description = description || defaultDescription;
  url = `https://bagel.md${url || defaultURL}`;
  image = image || defaultImage;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="viewport" property="viewport" content="width=device-width, initial-scale=1" />

      <meta name="title" property="title" content={title} />
      <meta name="description" property="description" content={description} />
      <meta name="image" property="image" content={image} />
      <link rel="canonical" href={url} />

      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" href="/favicon.ico" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <meta name="msapplication-TileColor" property="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" property="theme-color" content="#ffffff" />

      <meta name="og:url" property="og:url" content={url} />
      <meta name="og:title" property="og:title" content={title} />
      <meta name="og:description" property="og:description" content={description} />
      <meta name="twitter:site" property="twitter:site" content={'@bagelmd'} />
      <meta name="twitter:card" property="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" property="twitter:image" content={image} />
      <meta name="og:image" property="og:image" content={image} />
      <meta name="og:image:width" property="og:image:width" content="1194" />
      <meta name="og:image:height" property="og:image:height" content="691" />

      <script src="https://cdn.auth0.com/js/auth0/9.5.1/auth0.min.js" />
    </Helmet>
  );
};

export default Head;
