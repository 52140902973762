import React, { Component } from 'react';
import { Modal, message } from 'antd';

import store from '../store';
import * as api from '../api';
import { pdfModalDuck } from '../ducks/root';

export default class FirstCheckModal extends Component {
  state = { show: true, email: '', pending: false };

  constructor(props) {
    super(props);
    window.emailRef = this.emailRef = React.createRef();
    this.focus = this.focus.bind(this);
  }

  focus = () => {
    this.emailRef.current.focus();
  };

  componentDidMount() {
    setTimeout(this.focus, 300);
  }

  componentWillUnmount() {
    this.setState({ show: false });
  }

  render() {
    return (
      <Modal
        title="Get PDF checklist"
        visible={this.state.show}
        okText={'Get PDF'}
        onOk={async () => {
          if (this.state.pending) return;
          this.setState({ pending: true });
          message.loading('Sending email...', 0);
          try {
            await api.post(['sendPDF'], { email: this.state.email });
            store.dispatch(pdfModalDuck.clear());
            message.destroy();
            message.info('Email sent!');
          } catch (err) {
            console.log('ERROR');
            console.log(err);
            // message.error(err.message);
          }
          this.setState({ pending: false });
        }}
        onCancel={() => {
          store.dispatch(pdfModalDuck.clear());
        }}>
        <div className="col wide jc ac">
          <div className="row wide js ac">
            <label style={{ marginRight: '10px' }}>Email address</label>
            <input
              ref={this.emailRef}
              placeholder="doogie@howser.com"
              style={{ fontSize: '11pt' }}
              onChange={(evt) => {
                this.setState({ email: evt.target.value });
              }}
            />
          </div>

          <p style={{ marginTop: '15px', fontSize: '10pt', opacity: 0.7 }}>
            We'll send the PDF to the provided address. It should appear immediately.
          </p>
        </div>
      </Modal>
    );
  }
}
