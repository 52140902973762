import React from 'react';
import TodoItem from '../TodoItem';

const PracticeList = props => {
  // const todos = props.todos || {};

  return (
    <div className="subList" id="pricing">
      <h2 className="title">Pricing</h2>
      <p className="subtitle">Consider your fees and markups</p>
      <div className="items">
        <TodoItem id="practice-pricing-structure">
          <p className="title">Choose membership prices</p>
          <div className="description">
            There are lots of options: flat rate, age tiers, couple/family plans, annual discounts,
            or some combination thereof. Some practices charge a one-time enrollment fee. Others
            have no initial fee but charge for re-enrollment. Some practices charge a low per-office
            visit (something that my be required depending on your state regulations).
            <br />
            <br />
            Some practices also do access/quality tiering: for instance, a Premium plan that
            includes guaranteed same-day scheduling, more comprehensive preventative testing, home
            visits, increased personalization, etc. This is an effective way to do some price
            discrimimation: if there is a wealthy subpopulation in your area that's willing to pay
            extra for preventative care, let them! For an example of a practice that offers a
            low-price DPC membership and a premier concierge offering, check out{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://familyfirsthealthcenter.com/meet-dr-delicia/">
              Family First Health Center
            </a>
            . However, you should make it clear in your marketing materials that your members are
            paying for <em>care, not access</em> — otherwise, you could be classified as an
            insurance plan by a zealous insurance commissioner.
            <br />
            <br />
            Click around the DPC Frontier{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://mapper.dpcfrontier.com">
              mapper
            </a>{' '}
            to see what other people are doing.
          </div>
        </TodoItem>
        <TodoItem id="pricing-reenrollment">
          <p className="title">Decide on a re-enrollment policy</p>
          <div className="description">
            It may take some time to figure out what's best for your practice based on your patient
            panel makeup. Some docs refuse to accept back any patient who disenrolled or missed a
            payment. Others require back-payment of all membership fees missed during a gap in
            membership. Others charge a one-off re-enrollment fee. This should be at least twice
            your monthly fee to discourage on-again-off-again behavior.
          </div>
        </TodoItem>
        <TodoItem id="practice-meds-markup">
          <p className="title">Rx markups</p>
          <div className="description">
            If you do in-office dispensing, decide whether to mark up dispensed meds. You can mark
            up meds quite a bit and still offer your patients a great deal compared to the pharmacy
            prices. It could be a good source of additional revenue for your practice. That said,
            offering "wholesale prices" on drugs is often a compelling part of your sales pitch.
          </div>
        </TodoItem>
        <TodoItem id="practice-labs-markup">
          <p className="title">Lab markups</p>
          <div className="description">
            Just as for medications, it is possible to mark up the cost of lab work while still
            offering your patients a great deal.
            <br />
            <br />
            For pathology services, some states have laws against price markup by physicians; see
            the "anti-markup" states listed{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://capatholo.gy/2I3lFoo">
              here
            </a>
            . Still more states require disclosure to patients of the actual cost charged by the lab
            (see "disclosure" states{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://capatholo.gy/2I3lFoo">
              here
            </a>
            ). You should probably be doing this anyway for the sake of price transparency.{' '}
          </div>
        </TodoItem>
        <TodoItem id="pricing-per-visit-fee">
          <p className="title">Per-visit fee</p>
          <div className="description">
            Some practices charge a low per-visit fee to keep visit demand manageable. If you have a
            problem with excessive unnecessary visits, consider this.{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.dpcfrontier.com/defined/">
              According to DPC Frontier
            </a>
            , this fee should be lower than your monthly fee if you wish to qualify as DPC in most
            states.
          </div>
        </TodoItem>
        <TodoItem id="pricing-ffs-visits">
          <p className="title">Consider one-time cash-pay visits for non-members</p>
          <div className="description">
            Some practices still do one-off visits with non-member patients, either for additional
            revenue or as a way to attract new members. During the visit, describe DPC to the
            patient and offer to credit the cost of the current visit towards their enrollment
            fee/first monthly fee. This can be a successful marketing tool.
          </div>
        </TodoItem>
        <TodoItem id="pricing-annual-contracts">
          <p className="title">Consider one-year contracts</p>
          <div className="description">
            Some practices do this to mitigate the headache of billing management. For larger
            expenses, patients are more likely to pay by check or bank transfer, which means you
            lose less money to credit card fees. Plus you don't have to track down patients whose
            card failed on a monthly basis.
          </div>
        </TodoItem>
        <TodoItem id="pricing-billing-cycles">
          <p className="title">Billing cycles</p>
          <div className="description">
            Consider whether to bill in advance or in arrears. Billing in arrears (at the end of a
            month of membership) may seem unintuitive, but it puts you on more solid legal ground.
            Specifically, it is an excellent thing to point to if anyone (an insurance commisioner,
            for instance) accuses you of being an insurance plan. Billing in arrears also helps
            qualify you as an eligible expense for patients paying with an HRA or FSA. Note that DPC
            memberships shouldn't be paid from an HSA (Health Savings Account) — see the DPC
            Frontier discussion{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.dpcfrontier.com/health-savings-accounts/">
              here
            </a>
            . Some docs accept payment from HSAs without retribution; this issue has yet to be
            litigated.
          </div>
        </TodoItem>
      </div>
    </div>
  );
};

export default PracticeList;
