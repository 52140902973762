import React from 'react';
import TodoItem from '../TodoItem';

const SoftwareList = props => {
  // const todos = props.todos || {};

  return (
    <div className="subList" id="software">
      <h2 className="title">Software</h2>
      <p className="subtitle">Sign up for the services you need to run a practice</p>
      <div className="items">
        <TodoItem id="tech-ehr">
          <p className="title">Electronic health record</p>
          <div className="description">
            The main contenders are Elation, Atlas.md, and Bagel (that's us!). We started Bagel with
            the goal of fixing some major limitations imposed by the other major options. Read more
            about what we do better{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://bagel.md#letter">
              here
            </a>
            . We're still under development—but you should join{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/groups/bagelmd/">
              our Facebook group
            </a>{' '}
            to stay updated! We'll be posting videos of new features as we implement them and
            accepting feedback from the DPC community. It's a rare opportunity to shape your dream
            EHR software. You can join the group{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/groups/bagelmd/">
              here
            </a>
            .
          </div>
        </TodoItem>
        <TodoItem id="tech-member-mgmt">
          <p className="title">Membership management </p>
          <div className="description">
            You'll need a way to keep track of your members, enroll new patients, and manage billing
            in an automated way. We do this too! Bagel is an all-on-one software for DPC, not just
            an EHR. We help you manage charting, patient billing, scheduling, e-prescribing, lab/rad
            ordering, patient communication, and more. Other options are Atlas (another all-on-one
            software) and{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://www.hint.com/">
              Hint
            </a>
            .
          </div>
        </TodoItem>
        <TodoItem id="tech-patient-comm">
          <p className="title">Patient communication</p>
          <div className="description">
            <p>
              Having a second phone number for patients to call/text is vastly preferable to giving
              out your personal number. Many tools also let you route texts/calls from your "virtual
              number" to the device/number of your choice based on the time of day. This
              functionality is built into Bagel.
            </p>
            <p>
              There's a huge diversity of tools out there, each of which offers a unique feature set
              and interface. Two good ones are{' '}
              <a target="_blank" rel="noopener noreferrer" href="https://www.sprucehealth.com/">
                Spruce Health
              </a>{' '}
              and{' '}
              <a target="_blank" rel="noopener noreferrer" href="http://www.hale.co/product">
                Hale
              </a>
              , both of which offer secure messaging, phone calls, teleconferencing, and a dedicated
              mobile app.
            </p>
            <p>
              If you're just looking for a second phone number to use as a "business line", check
              out{' '}
              <a target="_blank" rel="noopener noreferrer" href="https://voice.google.com">
                Google Voice
              </a>{' '}
              or{' '}
              <a target="_blank" rel="noopener noreferrer" href="https://ringcentral.com">
                RingCentral
              </a>
              .
            </p>
          </div>
        </TodoItem>
        <TodoItem id="tech-inventory-mgmt">
          <p className="title">Inventory management service</p>
          <div className="description">
            You'll need this if you do in-office dispensing. (This is also built-in to Bagel.) There
            are many altneratives, most of which are surprisingly expensive:{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.mdscripts.com/features.html">
              MDScripts
            </a>
            ,{' '}
            <a target="_blank" rel="noopener noreferrer" href="http://www.flexscanmd.com/">
              Flexscan
            </a>
            , and{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://www.dispensepoint.com/">
              DispensePoint
            </a>
            to name a few.
          </div>
        </TodoItem>
        <TodoItem id="tech-eprescribing">
          <p className="title">E-prescribing</p>
          <div className="description">
            Some EHRs offer this as in integrated tool — including Bagel. There are also plenty of
            standalone e-prescribing tools out there; MDScripts and DrFirst are popular (but can be
            expensive).
          </div>
        </TodoItem>
        <TodoItem id="tech-efax">
          <p className="title">E-faxing</p>
          <div className="description">
            Also integrated into Bagel. Some other standalone options are{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://www.hellofax.com/">
              HelloFax
            </a>
            ,{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://www.ringcentral.com">
              RingCentral
            </a>
            ,{' '}
            <a target="_blank" rel="noopener noreferrer" href="http://www.metrofax.com">
              MetroFax
            </a>
            , and{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://doximity.com">
              Doximity
            </a>
            .
          </div>
        </TodoItem>
        <TodoItem id="tech-dictation">
          <p className="title">Dictation software</p>
          <div className="description">
            Use dictation to make charting easier and faster. Dragon is widely used and offers a
            both generic and medical-focused versions (the medical version is more expensive). Apple
            users can use the baked-in Siri dictation on their Macs/iDevices, though your mileage
            may vary.
          </div>
        </TodoItem>
        <TodoItem id="tech-textexpander">
          <p className="title">Text expander/macros</p>
          <div className="description">
            If you prefer typing notes, you can still expedite the process with a good snipper/macro
            software like TextExpander or Breevy. These tools let you specify snippets (e.g. "ros")
            that automatically expand into a full block of text (e.g. a Review of Systems). You can
            also specify fillable fields inside each snippet.
          </div>
        </TodoItem>
        <TodoItem id="tech-virtual-assistant">
          <p className="title">Virtual assistant</p>
          <div className="description">
            Some DPC docs use a service called{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://hellorache">
              HelloRache
            </a>{' '}
            to "automate" a lot of the burden of charting. This service gives you a trained virtual
            assistant/scribe who listens in on your patient visits via videochat and charts them in
            real time.
          </div>
        </TodoItem>

        <TodoItem id="tech-misc">
          <p className="title">Check out these other useful services</p>
          <div className="description">
            <a target="_blank" rel="noopener noreferrer" href="https://www.dropbox.com/">
              Dropbox
            </a>
            <br />
            store files in the cloud (HIPAA-compliant with a Dropbox Business account)
            <br />
            <br />
            <a target="_blank" rel="noopener noreferrer" href="https://rubiconmd.com/">
              Rubicon
            </a>
            <br />
            consult a network of specialists to quickly get answers to hard medical problems
            <br />
            <br />
            <a target="_blank" rel="noopener noreferrer" href="https://intakeq.com/">
              IntakeQ
            </a>
            <br />
            single tool to create customized web forms and let patients electronically sign
            documents
            <br />
            <br />
            <a target="_blank" rel="noopener noreferrer" href="https://www.followupthen.com/">
              FollowUpThen
            </a>
            <br />
            easily schedule email reminders for yourself - there's a lot to remember when starting a
            practice
          </div>
        </TodoItem>
      </div>
    </div>
  );
};

export default SoftwareList;
