import React from 'react';
import TodoItem from '../TodoItem';

const BusinessList = props => {
  // const todos = props.todos || {};

  return (
    <div className="subList" id="insurance">
      <h2 className="title">Insurance</h2>
      <p className="subtitle">Find plans to protect you, your business, and your employees </p>
      <div className="items">
        <TodoItem id="ins-malpractice">
          <p className="title">Malpractice insurance</p>
          <div className="description">
            You might want to involve an independent broker that is familiar with direct primary
            care, or at least private practice in general. These quotes vary hugely state-by-state.
            Be sure to ask for part-time rates, since you won't be fully booked in the early days.
          </div>
        </TodoItem>
        <TodoItem id="ins-workers-comp">
          <p className="title">Worker's compensation</p>
          <div className="description">
            If you employee people, you'll possibly be required to have a workers' compensation plan
            in place. Check out state-by-state laws on this{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://bit.ly/2BKBZEQ"
              // href="https://www.nfib.com/content/legal-compliance/legal/workers-compensation-laws-state-by-state-comparison-57181/"
            >
              here
            </a>
            .
          </div>
        </TodoItem>
        <TodoItem id="ins-disability-insurance">
          <p className="title">Disability insurance</p>
          <div className="description">
            If you have employees and live in California, Hawaii, New Jersey, New York and Rhode
            Island, you are required to provide some form of short-term disability insurance to your
            employees.
          </div>
        </TodoItem>
        <TodoItem id="ins-catastrophe">
          <p className="title">Business interruption insurance</p>
          <div className="description">
            Some practices in natural-disaster-prone areas may consider getting catastrophe
            insurance to make up for lost revenues.
          </div>
        </TodoItem>
        <TodoItem id="ins-property">
          <p className="title">Commercial property insurance (if applicable)</p>
        </TodoItem>
        <TodoItem id="ins-auto">
          <p className="title">Commercial auto insurance (if applicable)</p>
        </TodoItem>
      </div>
    </div>
  );
};

export default BusinessList;
