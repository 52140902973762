import React from 'react';
// import gql from 'graphql-tag';
import { connect } from 'react-redux';
import { Icon } from 'antd';

import store from '../store';
import { saveProgressModalDuck } from '../ducks/root';
// import { Mutation } from 'react-apollo';

// import * as sassVars from '../style/_vars.scss';
// import apollo from '../apollo';
// import getIDs from './ids';
// import * as API from '../api';

// const SET_TODO = gql`
//   mutation setTodo($slug: String!, $value: Boolean!) {
//     setTodo(slug: $slug, value: $value) {
//       id
//       slug
//       value
//     }
//   }
// `;

class TodoItem extends React.Component {
  state = { checked: false };

  componentDidMount() {
    if (typeof window !== 'undefined') {
      let value = window.localStorage.getItem(this.props.id);
      this.setState({ checked: value === 'true' });
    }

    // let ids = getIDs();
    // const id = this.props.id;
    // if (ids[id]) {
    //   // console.log('Duplicate ID: ' + id);
    // } else {
    //   ids[id] = true;
    // }
  }

  show = modalName => () => {
    this.setState({ [modalName]: true });
  };
  hide = modalName => () => {
    this.setState({ [modalName]: false });
  };

  render() {
    // <Mutation mutation={SET_TODO}>
    //   {setTodo => {}}
    // </Mutation>
    // return (

    // console.log(JSON.stringify({ data, loading, error, called }, null, 2));
    let checked = this.state.checked || false;
    return (
      <div className="row js as todo">
        <div
          className={`mr8 checkbox ${checked ? 'checked' : ''}`}
          onClick={async () => {
            console.log(this.props.id);
            const newValue = !checked;
            console.log(`setting to ${newValue}`);
            if (typeof window !== 'undefined') localStorage.setItem(this.props.id, newValue);
            this.setState({ checked: newValue });

            if (
              typeof window !== 'undefined' &&
              localStorage.getItem('hasSeenSaveProgressModal') !== 'true'
            ) {
              // TODO: show popup
              localStorage.setItem('hasSeenSaveProgressModal', true);
              store.dispatch(saveProgressModalDuck.set(true));
              // alert('Your first todo!');
            }

            // const newTodo = await setTodo({
            //   variables: { slug: this.props.id, value: newValue }
            // });
            // console.log('setTodo result!');
            // console.log(JSON.stringify(newTodo, null, 2));
          }}>
          {checked ? <Icon type="check" style={{ fontSize: '18px', color: 'white' }} /> : null}
        </div>
        <div className="fl1 content">{this.props.children}</div>
      </div>
    );

    // );
  }
}

const mstp = (state, ownProps) => {
  let todoState = false;
  if (state.todos) {
    // console.log(`checked? ${ownProps.id}: ${state.todos[ownProps.id]}`);
    todoState = state.todos[ownProps.id];
  }

  return {
    checked: todoState,
    idToken: state.idToken,
    accessToken: state.accessToken,
    expiresAt: state.expiresAt
  };
};

export default connect(mstp)(TodoItem);
