import React from 'react';
import TodoItem from '../TodoItem';

const LearnList = () => {
  // const todos = props.todos || {};

  return (
    <div className="subList" id="learn">
      <h2 className="title">Learn</h2>
      <p className="subtitle">Familiarize yourself with these resources for aspiring DPC docs</p>
      <div className="items">
        <TodoItem id="learn-dpcf">
          <p className="title">
            <a target="_blank" rel="noopener noreferrer" href="https://dpcfrontier.com">
              DPC Frontier
            </a>
          </p>
          <div className="description">
            This is the largest aggregation of resources for aspiring DPC doctors. There are guides
            to DPC-relevant state and federal regulations, information on how to start a practice,
            compilations of conference recordings, a list of upcoming events, a fascinating blog,
            and the DPC Mapper. Spend some time clicking around.
          </div>
        </TodoItem>
        <TodoItem id="learn-conferences">
          <p className="title">DPC Conferences</p>
          <div className="description">
            <p>There are three main DPC conferences each year.</p>
            <ul className="mt16">
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://d4pcfoundation.org/dpc2018/">
                  DPC Nuts and Bolts
                </a>{' '}
                — put on by the Docs 4 Patient Care Foundation
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer" href="http://dpcsummit.org">
                  DPC Summit
                </a>{' '}
                — put on by the AAFP, FMEC, and ACOFP
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://summit.hint.com/">
                  Hint Summit
                </a>{' '}
                — put on by the patient billing company Hint
              </li>
            </ul>

            <p>
              Consider attending one - being surrounded by other doctors who've already made the
              leap is invaluable. All three conferences are at least partially geared towards
              aspiring docs who are either trying to decide whether to do DPC or in very early
              planning stages.
            </p>
            {false && (
              <p>
                There is a YouTube channel that has compiled all the talks from these conferences
                for the past couple years in one place. Check out that channel{' '}
                <a target="_blank" rel="noopener noreferrer" href="http://bit.ly/2WxKq4U">
                  here
                </a>
                .
              </p>
            )}
          </div>
        </TodoItem>
        <TodoItem id="marketing-dpc-nation">
          <p className="title">
            <a target="_blank" rel="noopener noreferrer" href="https://dpcnation.org">
              DPC Nation
            </a>
          </p>
          <div className="description">
            <p>
              <a target="_blank" rel="noopener noreferrer" href="https://dpcnation.org">
                DPC Nation
              </a>{' '}
              is a patient-centric site focused on educating patients about DPC. Reading through it
              can help you teach patients about DPC in an accessible way.
            </p>
          </div>
        </TodoItem>
        {false && (
          <TodoItem id="learn-dpcdocs">
            <p className="title">DPC Docs Facebook Group</p>
            <div className="description">
              DPC Docs is by far the most active online community for direct primary care. You can
              get your questions answered, find templates for legal/marketing documents, and—if
              you're still in the system—get free therapy. It's a secret group, so you have to be
              invited to join. If you know an existing DPC doctor, ask them to add you. Otherwise,
              message one of the Page Admins:{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/christine.degnon.10">
                Dr. Degnon
              </a>
              ,{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/robin.dickinson.71">
                Dr. Dickinson
              </a>
              ,{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/jdavis1732">
                Dr. Davis
              </a>
              ,{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/melissa.phillips.946">
                Dr. Phillips
              </a>
              , or{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/trulypeppy">
                Dr. Benson
              </a>
            </div>
          </TodoItem>
        )}

        <TodoItem id="learn-facebook-groups">
          <p className="title">Facebook Groups</p>
          <div className="description">
            There are various Facebook groups for DPC practitioners. For some, you'll need to be
            invited by a current DPC doctor.
          </div>
        </TodoItem>

        <TodoItem id="learn-dpca">
          <p className="title">
            <a target="_blank" rel="noopener noreferrer" href="https://dpcalliance.com">
              DPC Alliance
            </a>
          </p>
          <div className="description">
            The DPC Alliance formed in 2018 as a national advocacy and support organization. They
            have a limited list of resources at{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://dpcalliance.org/resources">
              https://dpcalliance.org/resources
            </a>
            .
          </div>
        </TodoItem>

        <TodoItem id="learn-regional-alliance">
          <p className="title">Regional DPC alliances</p>
          <div className="description">
            Your area may contain a regional alliance. Google around for a website or Facebook
            group. Reach out to other DPCs in the area. Often they'll be more than willing to show
            you around their offices and discuss their workflows.
          </div>
        </TodoItem>
        {false && (
          <TodoItem id="learn-atlas-curriculum">
            <p className="title">the Atlas.md curriculum</p>
            <div className="description">
              See the topic listing{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://atlas.md/dpc-curriculum/topics/">
                here
              </a>{' '}
              and a ton of useful documents in the{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://atlas.md/dpc-curriculum/starter-packet/">
                Starter Packet
              </a>{' '}
              (most of which are linked elsewhere in this checklist).
            </div>
          </TodoItem>
        )}
        <TodoItem id="learn-d4pcf-newsletter">
          <p className="title">
            <a target="_blank" rel="noopener noreferrer" href="https://d4pcfoundation.org/">
              Docs 4 Patient Care Foundation
            </a>
          </p>
          <div className="description">
            The D4PCF puts on the largest annual DPC conference, runs a direct-care-focused{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://d4pcfoundation.org/category/doctors-lounge/">
              radio show
            </a>
            , and have an email newsletter that's worth checking out.
          </div>
        </TodoItem>
        <TodoItem id="learn-farrago">
          <p className="title">
            <em>Doug Farrago's Guide</em>
          </p>
          <div className="description">
            Longtime DPC doctor Doug Farrago wrote an book called{' '}
            <em>The Official Guide to Starting Your Own Direct Primary Care Practice</em>. Find it
            on Amazon{' '}
            <a href="https://amzn.to/2wM70ao" target="_blank" rel="noopener noreferrer">
              here
            </a>
            .
          </div>
        </TodoItem>

        {false && (
          <TodoItem id="learn-gunther">
            <p className="title">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="/documents/you-are-smart-enough.pdf">
                Julie Gunther's DPC business plan guide
              </a>
            </p>
            <div className="description">
              Julie Gunther from{' '}
              <a target="_blank" rel="noopener noreferrer" href="https://sparkmd.com">
                sparkMD
              </a>{' '}
              wrote an excellent guide for creating a business plan for your DPC practice. Check it
              out{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="/documents/you-are-smart-enough.pdf">
                here
              </a>
              .
            </div>
          </TodoItem>
        )}
      </div>
    </div>
  );
};

export default LearnList;
