import axios from 'axios';
import store from './store';
import * as globals from './globals';
import * as util from './util';
import { handle } from './error';
import { message } from 'antd';

const getToken = () => {
  let accessToken = store.getState().accessToken || '';
  if (!accessToken) {
    // return new Promise((res, rej) => rej);
  }
  return `Bearer ${accessToken}`;
};

export const request = async (method, path, data) => {
  console.log(method);
  console.log(path);
  console.log(data);

  try {
    const token = await getToken();
    // console.log(token);
    let url = `${globals.serverURL}/${path}`;
    console.log(url);
    let options = {
      method,
      url,
      data,
      headers: {
        Authorization: token
      }
    };
    const result = await axios(options);

    return new Promise((res) => {
      res(result.data);
    });
  } catch (err) {
    return new Promise((res, rej) => {
      message.destroy();
      rej(err);
      setTimeout(() => {
        handle(err);
      }, 50);
    });
  }
};

export const post = async (path, data) => {
  return request('post', path.join('/'), data);
};

export const get = async (path, data) => {
  return request('get', path.join('/') + (data ? `?${util.serialize(data)}` : ''));
};

export const put = async (path, data) => {
  return request('put', path.join('/'), data);
};

export const patch = async (path, data) => {
  return request('patch', path.join('/'), data);
};

export const del = async (path, data) => {
  return request('delete', path.join('/'), data);
};
