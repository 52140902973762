import React from 'react';
import TodoItem from '../TodoItem';

const OfficeList = props => {
  // const todos = props.todos || {};

  return (
    <div className="subList" id="office">
      <h2 className="title">Office</h2>
      <p className="subtitle" />
      <div className="items">
        <TodoItem id="office-ideal">
          <p className="title">Think about your ideal space</p>
          <div className="description">
            Most practices have a small dedicated space. It's okay to be small—say, just a waiting
            room and an exam room. Many DPCs have made that work. Others have rented spare rooms in
            nearby clinics or private practices, taken over a space from a local retiring doc, or
            even run a micro-practice out of their own home.
          </div>
        </TodoItem>
        <TodoItem id="office-space-rent-vs-lease">
          <p className="title">Decide whether to buy, rent, or lease</p>
        </TodoItem>
        <TodoItem id="office-select-ocation">
          <p className="title">Find a location</p>
          <div className="description">
            Finding commercial real estate is often harder than finding residential real estate.
            Lots of commercial places never make it onto Trulia or Zillow. Check out sites like{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://crexi.com">
              Crexi
            </a>{' '}
            or{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://loopnet.com">
              Loopnet
            </a>{' '}
            that focus on commercial listings. Or just drive around nearby strip malls and business
            parks looking for real estate signs.
          </div>
        </TodoItem>
        <TodoItem id="office-front-office">
          <p className="title">Arrange the front office space</p>
          <div className="description">
            Check out our Office Supplies Checklist{' '}
            <a target="_blank" rel="noopener noreferrer" href="/supplies">
              here
            </a>
            . We'll leave the interior decoration up to you.
          </div>
        </TodoItem>
        <TodoItem id="office-check-in">
          <p className="title">Figure out the front-office check-in process</p>
          <div className="description">
            You could hire a front-office person, do patient check-in yourself, or use an iPad/kiosk
            for check-in.
            <br />
            <br />
            Remember, hiring employees (even one) makes you subject to OSHA (see below).
          </div>
        </TodoItem>
        <TodoItem id="office-hr-payroll">
          <p className="title">If you have employees: set up HR/payroll software</p>
          <div className="description">
            These days, it's cheap, easy, and beats arranging a bank transfer by hand every two
            weeks.{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://quickbooks.intuit.com">
              QuickBooks
            </a>{' '}
            is a tried-and-true option.
          </div>
        </TodoItem>
        <TodoItem id="office-employee-perks">
          <p className="title">
            If you have employees: consider offering life, health, and accident insurance
          </p>
          <div className="description">And of course free DPC subscriptions!</div>
        </TodoItem>
      </div>
    </div>
  );
};

export default OfficeList;
