import React from 'react';
import { Icon } from 'antd';

import * as sassVars from '../style/_vars.scss';

class Header extends React.Component {
  state = { scroll: 0 };
  componentDidMount() {
    if (process.browser) {
      window.onscroll = () => {
        this.setState({ scroll: document.body.scrollTop });
      };
    }
  }

  scrollFork = (opt1, opt2) => {
    return this.state.scroll < 30 ? opt1 : opt2;
  };

  render() {
    const headerItem = 'fs12 mh2 btn';

    return (
      <div
        className={`wide`}
        style={{
          position: 'fixed',
          top: '0px',
          left: '0px',
          backgroundColor: '#ffffffce',
          zIndex: 1
        }}>
        {false && !this.props.hideAnnouncement && <div
          className="col jc ac"
          style={{ padding: '8px 10px', backgroundColor: `#730805` }}>
          <p
            style={{
              color: 'white',
              maxWidth: '800px',
              textAlign: 'center',
              lineSpacing: 1.3,
              padding: '0px '
            }}>
            <Icon type="info-circle" theme="filled" /> We just announced a new joint project with
            DPC Frontier called Brick Health! We hope to provide a new revenue stream for DPC practices.{' '}
            <a  style={{ textDecoration: 'underline', color: 'white' }}target="_blank" rel="noopener noreferrer" href="https://brick.health/clinics">{`LEARN MORE >>`}</a>
          </p>
        </div>}
        <div className={'wide row jb ac pv8 ph16'}>
          <a href="/" className="clickable">
            <img
              style={
                { width: '150px', maxWidth: '30vw' }
                // display: this.scrollFork('none', 'block'),
              }
              src="/img/gradient_wordmark.png"
              alt="Colorful Bagel logo"
              title="Colorful Bagel logo"
            />
          </a>
          <div className="row fs11">
            {false && (
              <a href="/#letter" className={`${headerItem} colorplain sm-hide`}>
                Why
              </a>
            )}
            <a href="/#features" className={`${headerItem} colorplain sm-hide`}>
              Features
            </a>
            <a href="/#pricing" className={`${headerItem} colorplain sm-hide`}>
              Pricing
            </a>

            <a href="/#team" className={`${headerItem} colorplain sm-hide`}>
              Team
            </a>
            <a href="/todo/" className={`${headerItem} colorplain sm-hide`}>
              Starting a DPC Practice?
            </a>

            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/groups/bagel.md"
              className={`${headerItem} coloroutline `}
              style={{ border: `2px solid ${sassVars.blueColor}` }}
              to="/#cta">
              Stay Updated
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
