import React from 'react';
import TodoItem from '../TodoItem';

const OshaList = props => {
  // const todos = props.todos || {};

  return (
    <div className="subList" id="osha">
      <h2 className="title">OSHA</h2>
      <p className="subtitle">
        If you have zero employees, you're done already!{' '}
        <span role="img" aria-label="party">
          🎉
        </span>
      </p>

      <div className="items">
        <TodoItem id="osha-biohazard-disposal">
          <p className="title">Find a biohazard/waste disposal service near you</p>
          <div className="description"> </div>
        </TodoItem>
        <TodoItem id="osha-best-practices">
          <p className="title">Follow best practices when dealing with hazardous chemicals/waste</p>
          <div className="description">
            If you use common sense, you're most of the way to OSHA compliance. Use safe sharps,
            protective equipment, universal precautions, and clearly marked waste containers. You
            know the drill.
          </div>
        </TodoItem>
        <TodoItem id="osha-hazardous-chemicals">
          <p className="title">Maintain a list of hazardous chemicals in your office</p>
          <div className="description">
            Keep track of all hazardous chemicals you store in your office, and make it available to
            your employees. Additionally, print out Safety Data Sheets for every chemical in stock.
            You can find most of them for free online.
          </div>
        </TodoItem>
        <TodoItem id="osha-fire-extinguisher">
          <p className="title">Buy a fire extinguisher and mount it on the wall</p>
          <div className="description"> </div>
        </TodoItem>
        <TodoItem id="osha-poster">
          <p className="title">Print the OSHA poster and put it on the wall</p>
          <div className="description">
            Here's a{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://bit.ly/1qgvTF1"
              // href="https://www.osha.gov/Publications/osha3165.pdf"
            >
              PDF
            </a>
            .
          </div>
        </TodoItem>
        <TodoItem id="osha-training">
          <p className="title">Put your employees through annual OSHA training</p>
          <div className="description">
            Check out{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://medtrainer.com/">
              Medtrainer
            </a>{' '}
            for an easy way to do online OSHA training (and many other certifications besides).
          </div>
        </TodoItem>
        <TodoItem id="osha-incident-reporting">
          <p className="title">Know how to report incidents to OSHA</p>
          <div className="description">
            Every employer is required to report any workplace incidents that result in a fatality
            or the hospitalization of three or more employees. You can report these online{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.osha.gov/pls/ser/serform.html">
              here
            </a>
            .
          </div>
        </TodoItem>
        <TodoItem id="osha-emergency-action-plan">
          <p className="title">With more than 10 employees: draft an emergency action plan</p>
          <div className="description">
            Any employer with more than 10 employees is required to create a written emergency
            action plan. See OSHA's{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://bit.ly/2QUi271"
              // href="https://www.osha.gov/dte/grant_materials/fy09/sh-18796-09/sample_emergencyactionplan.doc"
            >
              sample plan
            </a>
            .
          </div>
        </TodoItem>
        <TodoItem id="osha-injury-reporting">
          <p className="title">With more than 10 employees: do incident reporting and logging</p>
          <div className="description">
            Any employer with more than 10 employees is required to fill out an incident report
            (OSHA Form 301) of all workplace illnesses/injuries, going back at least 5 years. You
            must ALSO add each incident your your injury/illness log (OSHA Form 300). You must log
            some{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://bit.ly/2R0IFr0"
              // href="https://www.ctdol.state.ct.us/osha/sample_SharpsInjLog.pdf"
            >
              additional information
            </a>{' '}
            for any injury involving sharps.
            <br />
            <br />
            You must also fill out an annual injury/illness summary form (OSHA Form 300A). All these
            forms are available{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://bit.ly/1XMorza"
              // href="https://www.osha.gov/recordkeeping/new-osha300form1-1-04-FormsOnly.pdf"
            >
              here
            </a>
          </div>
        </TodoItem>
      </div>
    </div>
  );
};

export default OshaList;
