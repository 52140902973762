import React from 'react';
import TodoItem from '../TodoItem';

const VendorsList = props => {
  // const todos = props.todos || {};

  return (
    <div className="subList" id="vendors">
      <h2 className="title">Vendors</h2>
      <p className="subtitle">Establish your sources of drugs, labs, and specialists</p>
      <div className="items">
        <TodoItem id="vendor-gpo">
          <p className="title">Join one or more group purchasing organization (GPO)</p>
          <div className="description">
            <p>
              This is often the best and easiest way to get cash prices on medications, labs, and
              DME. In DPC, its common to join multiple.{' '}
              <a target="_blank" rel="noopener noreferrer" href="https://groupsourceinc.com">
                Groupsource
              </a>{' '}
              or{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.pedspal.org">
                PedsPal
              </a>{' '}
              can get you deals on medications and DME from Henry Schein.{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.healthcareprocurement.com">
                Healthcare Procurement Solutions
              </a>{' '}
              gets you deals from Labcorp, Quest, McKesson, and Medline. Joining is free and can
              save you a lot of time negotiating with individual vendors.
            </p>
            <p>
              If you choose to go this route, a lot of the todo items below are irrelevant. You'll
              be provided with low cash prices on labs from Labcorp/Quest, medications from Henry
              Schein, and DME from McKesson/Henry Schein.{' '}
            </p>
          </div>
        </TodoItem>
        <TodoItem id="vendor-wholesale-meds">
          <p className="title">
            If you're doing in-office dispensing: sign up with a wholesale medication distributor
          </p>
          <div className="description">
            If you join a GPO you'll likely be provided with cash pricing on medications from Henry
            Schein or something similar. If you don't join a GPO, sign up with{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://www.andameds.com/login.htm">
              Andameds
            </a>{' '}
            or directly with{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.henryschein.com">
              Henry Schein
            </a>
            .
          </div>
        </TodoItem>
        <TodoItem id="vendor-pharmacy-deals">
          <p className="title">
            If you're NOT doing in-office dispensing: help your patients save money at the pharmacy
          </p>
          <div className="description">
            Point them in the direction of{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://www.blinkhealth.com/">
              Blink Health
            </a>
            ,{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://www.goodrx.com/">
              GoodRX
            </a>
            ,{' '}
            <a target="_blank" rel="noopener noreferrer" href="http://www.marleydrug.com/">
              Marley Drug
            </a>
            , and{' '}
            <a target="_blank" rel="noopener noreferrer" href="http://www.needymeds.org/">
              NeedyMeds
            </a>
            .
          </div>
        </TodoItem>
        <TodoItem id="vendor-lab-prices">
          <p className="title">Establish a relationship with a lab</p>
          <div className="description">
            For lab/pathology work, many DPC docs negotiate prices (often low or wholesale ones)
            with national laboratories (
            <a target="_blank" rel="noopener noreferrer" href="https://www.labcorp.com/">
              LabCorp
            </a>
            ,{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.questdiagnostics.com">
              Quest
            </a>
            ,{' '}
            <a target="_blank" rel="noopener noreferrer" href="http://www.cedardx.com">
              Cedar
            </a>
            ,{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://www.cpllabs.com">
              CPL
            </a>
            ,{' '}
            <a target="_blank" rel="noopener noreferrer" href="http://www.lifelinescreening.com">
              Life Line
            </a>
            , etc). The lab then bills the DPC practice (not the patient) for their services. Some
            docs treat these labs as a benefit of membership and swallow the cost; others pass the
            cost through to the patient (potentially with a small markup). This can save your
            patients a lot of money.
            <br />
            <br />
            Offering this service to your patients requires negotiating prices with labs upfront. In
            these discussions, demand similar prices to those already established by previous DPC
            docs.
            <br />
            <br />
            Don't forget to also negotiate a blood draw fee, especially if you don't do draws
            in-office. The default fee is often high ($20) but they'll typically come down to a few
            bucks if you insist.
            <br />
            <br />
            There may be legal hurdles to doing this depending on your state. For non-pathology lab
            services, it is illegal in New York and New Jersey (see{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.dpcfrontier.com/laboratory-client-billing">
              here
            </a>
            ). For pathology services, it is illegal in many more states; see the "direct billing"
            states listed{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://capatholo.gy/2I3lFoo">
              here
            </a>
            .
            <br />
            <br />
            In these states, the patient must arrange payment directly with the lab. Contact the lab
            to determine the best way to go about this.
          </div>
        </TodoItem>
        <TodoItem id="vendor-imaging-prices">
          <p className="title">Establish a relationship with an imaging center</p>
          <div className="description">
            As with labs, it is possible to negotiate low prices with imaging centers.
          </div>
        </TodoItem>
        <TodoItem id="vendor-specialist-prices">
          <p className="title">Establish relationships with other providers</p>
          <div className="description">
            Consider arranging prices for bariatric surgery, breast health services, digestive
            disease specialists, endoscopies, eye surgery, general surgery, heart and vascular
            specialists, kidney stone treatment, oncology specialists, orthopedic surgery, pain
            management, physical therapy, radiology services, sleep health centers, and spine
            procedures.
            <br />
            <br />
            For your reference, price sheets for each of these categories can be found{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.kemptonpremierproviders.com/viewkpp.php?view=specialty">
              here
            </a>
            .
          </div>
        </TodoItem>
        <TodoItem id="vendor-ebay">
          <p className="title">eBay and Amazon</p>
          <div className="description">
            Often the lowest prices, especially more medical devices, will be on eBay and Amazon.
          </div>
        </TodoItem>
      </div>
    </div>
  );
};

export default VendorsList;
