import React from 'react';
import TodoItem from '../TodoItem';

const AdminList = props => {
  // // const todos = props.todos || {};

  return (
    <div className="subList" id="admin">
      <h2 className="title">Administration and Logistics</h2>
      <p className="subtitle" />
      <div className="items">
        <TodoItem id="admin-accounting">
          <p className="title">Set up accounting workflow</p>
          <div className="description">
            <a target="_blank" rel="noopener noreferrer" href="https://quickbooks.com">
              Quickbooks
            </a>
            ,{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://freshbooks.com">
              Freshbooks
            </a>
            , and{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://zoho.com">
              Zoho
            </a>{' '}
            are a few good options. Keeping track of all business expenses from the get-go will make
            life easier down the road. Make an effort to only buy things with your business
            credit/debit card, not a personal card.
          </div>
        </TodoItem>
        <TodoItem id="admin-hiring">
          <p className="title">Hiring</p>
          <div className="description">
            It easier than ever to post a job online and start hearing from candidates.{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://indeed.com">
              Indeed
            </a>{' '}
            and{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://ziprecruiter.com">
              ZipRecruiter
            </a>{' '}
            are easy to use and have a high success rate.
          </div>
        </TodoItem>
        <TodoItem id="admin-payroll">
          <p className="title">If you have employees: set up payroll</p>
          <div className="description">
            <a target="_blank" rel="noopener noreferrer" href="https://gusto.com">
              Gusto
            </a>{' '}
            is excellent for this. A couple other options are{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://zenefits.com">
              Zenefits
            </a>{' '}
            and{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://quickbooks.com">
              Quickbooks
            </a>
            . Any of these will make it easy to add new employees, set compensation schedules, and
            generate required tax documents when the time comes.
          </div>
        </TodoItem>
        <TodoItem id="business-bank-account">
          <p className="title">Open a checking account for your business</p>
          <div className="description">
            You'll likely need your business's EIN and Articles of Incorporation/Organization. For
            an LLC, some banks may also require a copy of your Operating Agreement.
          </div>
        </TodoItem>
        <TodoItem id="admin-credit-card">
          <p className="title">Apply for a business credit card</p>
          <div className="description">
            It's sometimes useful to have a line of credit available early on when your cash flow is
            minimal. It's also may be beneficial to building your business credit score if you
            expect to need loans at some point.
          </div>
        </TodoItem>
        <TodoItem id="admin-update-info">
          <p className="title">Update your contact info with everyone!</p>
          <div className="description">
            <p>
              Once you've found a physicial location (see "Location") and set up your email hosting
              (see "Website"), tell people your new information! Update nearby hospitals and private
              practices, the DEA, state pharmacy board, state licensing office, city business
              licensing office, local labs and radiology centers, and anyone else in the medical
              community who knows you. You don't want referred patients or requisitioned lab results
              ending up at your old employer's office instead of your own.
            </p>
            <p>This is also a great way to start a conversation with other doctors about DPC.</p>
          </div>
        </TodoItem>
      </div>
    </div>
  );
};

export default AdminList;
