import React from 'react';
import TodoItem from '../TodoItem';

const BusinessList = props => {
  // const todos = props.todos || {};

  return (
    <div className="subList" id="business">
      <h2 className="title">Business</h2>
      <p className="subtitle">DPC is for closers</p>
      <div className="items">
        <TodoItem id="business-plan">
          <p className="title">Draft a business plan</p>
          <div className="description">
            <p>
              Estimate initial upfront costs: including location renovation costs, equipment and
              materials, lawyer/accountant fees.
            </p>

            <p>Estimate operating costs: rent, utilities, payroll, non-durables, lab kits, Rx.</p>

            <p>
              Estimate revenues: your number of patients over time, and set revenue goals per month.
            </p>
            <p>
              Using this information, determine how much money you must spend before you break even.
            </p>
            {false && (
              <p>
                Using this information, determine how much money you must spend before you break
                even. For a great walkthrough of this calculation, check out Julie Gunther's{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/documents/you-are-smart-enough.pdf">
                  DPC Startup Handbook
                </a>
                .
              </p>
            )}
          </div>
        </TodoItem>
        <TodoItem id="business-loan">
          <p className="title">If you need a loan: do your research</p>
          <div className="description">
            Check out this{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://bit.ly/2MARV6n">
              // href="https://www.doctorloanusa.com/14-things-to-know-about-physician-loans/"
              comprehensive review
            </a>{' '}
            of physician loans.{' '}
          </div>
        </TodoItem>
        <TodoItem id="business-workshop">
          <p className="title">Consider completing a small business workshop</p>
          <div className="description">
            Check your local college for cheap courses or search for an online course. Sometimes a
            certificate of completion will convince a bank to give you better rates on loans.
          </div>
        </TodoItem>
      </div>
    </div>
  );
};

export default BusinessList;
