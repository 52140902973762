import React, { Component } from 'react';
import { Input, message, Spin, Icon } from 'antd';
import * as api from '../api';
// import store from '../store';

const Search = Input.Search;
export default class WaitlistInput extends Component {
  state = { email: '', pending: false };
  render() {
    const { text, noText } = this.props;
    return (
      <div className="wide mxw400">
        {!noText && (
          <small className="blue" style={{ fontSize: '10pt', fontVariant: 'all-petite-caps' }}>
            Request early access
          </small>
        )}
        <Search
          size="small"
          placeholder="Email address"
          size="large"
          enterButton={
            this.state.pending ? (
              <Spin indicator={<Icon type="loading" style={{ color: 'white' }} spin />} />
            ) : (
              text || 'Submit'
            )
          }
          value={this.state.email}
          loading={this.state.pending}
          onChange={(evt) => {
            this.setState({ email: evt.target.value });
          }}
          onSearch={async () => {
            console.log(this.state.pending);
            if (this.state.pending) return;
            this.setState({ pending: true });
            // message.loading('Adding you to waitlist...', 0);

            try {
              await api.post(['waitlist'], {
                name: this.state.name,
                email: this.state.email
              });
              if (typeof window !== 'undefined') {
                window.localStorage.setItem('onWaitlist', true);
                window.location = '/joined';
              }
              // this.setState({ email: '' });
            } catch (err) {
              console.log('ERROR');
              console.log(err);
            }

            this.setState({ pending: false });
          }}
        />
      </div>
    );
  }
}
