import * as auth0 from 'auth0-js';
import store from './store';
import { idTokenDuck, accessTokenDuck, expiresAtDuck } from './ducks/root';
// import * as todoActions from './ducks/todo';
import * as globals from './globals';

const auth = new auth0.WebAuth({
  domain: globals.authDomain,
  audience: globals.apiId,
  clientID: globals.authClientId,
  responseType: 'token id_token',
  nonce: '',
  scope: 'openid profile email admin',
  redirectUri: globals.callbackURL
});

export function localLogin(authResult) {
  console.log('localLogin');

  if (typeof window !== 'undefined') {
    window.localStorage.setItem('isLoggedIn', 'true');
    // Set the time that the access token will expire at

    let expiresAtStr = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime());
    console.log(`expiresAtStr: ${expiresAtStr}`);
    window.localStorage.setItem('expiresAt', expiresAtStr);
    window.localStorage.setItem('accessToken', authResult.accessToken);
    window.localStorage.setItem('idToken', authResult.idToken);

    store.dispatch(expiresAtDuck.set(expiresAtStr));
    store.dispatch(accessTokenDuck.set(authResult.accessToken));
    store.dispatch(idTokenDuck.set(authResult.idToken));
  }
}

export async function renewTokens() {
  console.log('renewTokens');
  return new Promise((res, rej) => {
    auth.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        console.log('refreshing token');
        localLogin(authResult);
        res();
      } else if (err) {
        console.log('login expired...logging out');
        // alert('Could not get a new token ' + err.error + ':' + err.error_description + '.');
        logout();
        rej();
      }
    });
  });
}

export function logout() {
  if (typeof window !== 'undefined') {
    console.log('logout');
    // Remove isLoggedIn flag from localStorage
    window.localStorage.removeItem('isLoggedIn');
    // Remove tokens and expiry time
    window.localStorage.setItem('expiresAt', '0');
    window.localStorage.setItem('accessToken', '');
    window.localStorage.setItem('idToken', '');
  }

  store.dispatch(expiresAtDuck.clear());
  store.dispatch(accessTokenDuck.clear());
  store.dispatch(idTokenDuck.clear());
}

export function isAuthenticated(expiresAt) {
  // console.log('isAuthenticated');
  // Check whether the current time is past the
  // Access Token's expiry time

  var expiration = parseInt(expiresAt) || 0;
  let isAuth = new Date().getTime() < expiration;
  console.log('isAuthenticated: ' + isAuth);

  if (!isAuth) {
    logout();
  }
  return isAuth;
}

export default auth;
