import React from 'react';
import TodoItem from '../TodoItem';

const OptoutList = props => {
  // const todos = props.todos || {};

  return (
    <div className="subList" id="optout">
      <h2 className="title">Insurance Opt-out</h2>
      <p className="subtitle">Finally say bye to Medicare</p>
      <div className="items">
        <TodoItem id="optout-timing">
          <p className="title">Decide when to opt out of Medicare</p>
          <div className="description">
            It is possible to open your practice while remaining opted-in to Medicare. Simply don't
            accept any Medicare-covered patients. Instead just take their contact info and reach out
            to them again when you are able to opt-out.
            <br />
            <br />
            On the other side of the coin, it's possible to moonlight as an employed physician even
            after opting out, though only in limited circumstances. These include positions in
            occupational medicine, correctional medicine, addiction medicine, and urgent care.
            Hospice care is also possible, but only if your position is administrative in nature.
            These options are outlined in greater detail{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.dpcfrontier.com/opted-out-moonlighting/">
              here
            </a>
            .
          </div>
        </TodoItem>

        <TodoItem id="optout-affidavit">
          <p className="title">Complete an opt-out affidavit</p>
          <div className="description">
            Look up your state{' '}
            <a target="_blank" href="/optout">
              here
            </a>{' '}
            to find a link to the proper opt-out affidavit and the address to mail it to.
          </div>
        </TodoItem>
        <TodoItem id="optout-mail-affidavit">
          <p className="title">Mail affidavit at the appropriate time</p>
          <div className="description">
            <p>You'll need to know some details of the opt-out process.</p>
            <p>
              <em>For participating providers</em> in Medicare, new "batches" of Medicare opt-outs
              are made active on the first day of each calendar quarter (Jan 1, Apr 1, July 1, Oct
              1). The Medicare carrier must receive your opt-out affidavit <b>30 days prior</b> to
              the stated effective date (Dec 1, Mar 1, June 1, Sept 1, respectively). So if you
              start the opt-out process on December 2nd, the earliest you can be officially opted
              out is April 1. Plan accordingly.
            </p>
            <p>
              <em>If you are currently non-participating (NON-PAR)</em>, your opt-out will take
              effect <b>immediately</b> upon their receipt of your opt out form. If you are still
              employed or moonlighting, beware! You don't want to opt out with two months left
              before you quit.
            </p>
            <br />
            <br />
            Send the affidavit to the proper address (listed by state{' '}
            <a target="_blank" href="/optout">
              here
            </a>
            ). I recommend using a method with a guaranteed delivery date and proof of delivery
            (such as Certified Mail, return receipt requested).
            <br />
            <br />
            If you wish, include a letter/prepaid envelope requesting that the carrier acknowledge
            in writing that the opt out was properly accomplished.
          </div>
        </TodoItem>
        <TodoItem id="optout-abn-form">
          <p className="title">
            Find an Advance Beneficiary Notice of Noncoverage (ABN) form online
          </p>
          <div className="description">
            All new patients must sign an ABN to acknowledge that you are opted out of Medicare.
            English and Spanish forms are available for download{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://go.cms.gov/1JWmDQ7">
              here
            </a>
            .
          </div>
        </TodoItem>

        <TodoItem id="optout-medicaid">
          <p className="title">
            Notify the state Medicaid program that you would like to privately contract with
            patients
          </p>
          <div className="description">
            This may require that you dis-enroll as there is no “opt out” process with Medicaid.
            Some states may ask you to sign up as an ordering and referring only provider. For
            details on this process within your home state, Google "[state] Medicaid ordering and
            referring enrollment".
            <br />
            <br />
            See the full DPC Frontier dicussion on Medicaid{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.dpcfrontier.com/medicaid/">
              here
            </a>
            .
          </div>
        </TodoItem>
        <TodoItem id="optout-insurance-companies">
          <p className="title">Private insurance</p>

          <div className="description">
            Often this must be done 90 days out.
            <br />
            <br />
            See a sample contract termination letter{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://bit.ly/2WMEzIn">
              here
            </a>
            . Notify all patients covered by that company that you are leaving their network (sample
            letter{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://bit.ly/2EYqE7Y">
              here
            </a>
            ).
          </div>
        </TodoItem>
      </div>
    </div>
  );
};

export default OptoutList;
