import React, { Component } from 'react';
import { Modal, message } from 'antd';
import * as api from '../api';
import store from '../store';
import { trelloModalDuck } from '../ducks/root';

export default class FirstCheckModal extends Component {
  state = { show: true, email: '', pending: false };

  componentWillUnmount() {
    this.setState({ show: false });
  }

  render() {
    return (
      <Modal
        title="Get Access to Asana Project"
        visible={this.state.show}
        onOk={async () => {
          if (this.state.pending) return;
          this.setState({ pending: true });
          message.loading('Requesting invite...', 0);
          try {
            await api.post(['asana'], {
              name: this.state.name,
              email: this.state.email
            });
            store.dispatch(trelloModalDuck.clear());
            message.destroy();
            message.info("Invite requested! We'll set up your Asana project as soon as possible.");
          } catch (err) {
            console.log('ERROR');
            console.log(err);
          }
          this.setState({ pending: false });
        }}
        okText="Request Asana access"
        onCancel={() => {
          store.dispatch(trelloModalDuck.clear());
        }}>
        <div className="wide">
          <p className="mb16">
            {`We need to manually add you to the Asana project, so you won't get access immediately.
          Once you provide your name and email, we'll add you to the project as soon as we can 👍`}
          </p>
          <label>Name</label>
          <br />
          <input
            placeholder="Name"
            onChange={(evt) => {
              this.setState({ name: evt.target.value });
            }}
          />
          <br />
          <label>Email</label>
          <br />
          <input
            placeholder="Email address"
            onChange={(evt) => {
              this.setState({ email: evt.target.value });
            }}
          />
        </div>
      </Modal>
    );
  }
}
