import React from 'react';
import TodoItem from '../TodoItem';

const LegalList = props => {
  // const todos = props.todos || {};

  return (
    <div className="subList" id="legal">
      <h2 className="title">Forms</h2>
      <p className="subtitle">All the forms and contracts you'll need to run your practice</p>
      <div className="items">
        <TodoItem id="forms-patient-agreement">
          <p className="title">Patient Agreement</p>
          <div className="description">
            Visit the websites of practices in your area (find them using the DPC Frontier Mapper).
            Some have a link to their Patient Agreements linked at the bottom of their websites. See
            some examples from
            <a href="https://bit.ly/2wKvYas" target="_blank" rel="noopener noreferrer">
              Vintage DPC
            </a>
            ,{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://bit.ly/2Wu0bFe">
              Hampton DPC
            </a>
            , and{' '}
            <a
              href="http://www.inspirehealthdpc.com/patient-agreement"
              target="_blank"
              rel="noopener noreferrer">
              Inspire Health
            </a>
            .
          </div>
        </TodoItem>
        <TodoItem id="forms-release-of-records">
          <p className="title">Release of Records form</p>
          <div className="description">
            See sample{' '}
            <a href={'/documents/release-of-records.doc'} target="_blank" rel="noopener noreferrer">
              here
            </a>
            .
          </div>
        </TodoItem>
        <TodoItem id="forms-billing-auth">
          <p className="title">Billing Authorization form</p>
          <div className="description">
            If you're accepting patient billing information on paper, you'll need an authorization
            form. If patients provide billing information online via your billing vendor, this won't
            be necessary. See a sample{' '}
            <a href="/documents/cc-auth.doc" target="_blank" rel="noopener noreferrer">
              credit/debit authorization form
            </a>{' '}
            and a sample ACH form{' '}
            <a target="_blank" rel="noopener noreferrer" href="/documents/ach-auth.doc  ">
              here
            </a>
            .
          </div>
        </TodoItem>
        <TodoItem id="forms-hx">
          <p className="title">Patient History form</p>
          <div className="description">
            See sample{' '}
            <a href="/documents/patient-hx.docx" target="_blank" rel="noopener noreferrer">
              here
            </a>
            .
          </div>
        </TodoItem>
        <TodoItem id="forms-employer-contracts">
          <p className="title">If hiring: Employee Contract</p>
        </TodoItem>
        <TodoItem id="forms-find-lawyer">
          <p className="title">Optionally, run everything by a lawyer</p>
          <div className="description">
            There are some very good sample documents available for free online or on the websites
            of established DPC practices. If you want to be 100% sure that your documents are
            airtight, run them by a lawyer familiar with medical law/regulation, or DPC in
            particular. Many practices use{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://www.leedslaw.us/">
              Luanne Leeds
            </a>{' '}
            who specializes in helping DPC practices.{' '}
          </div>
        </TodoItem>
      </div>
    </div>
  );
};

export default LegalList;
