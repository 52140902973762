import React from 'react';
// import Intercom from 'react-intercom';
// import Helmet from 'react-helmet';
import Header from './Header';
import Footer from './Footer';

import '../style/_common.scss';

export default class StandardPage extends React.Component {
  componentDidMount() {}
  render() {

    return (
      <div>
        {/* <div style={{height:"20px", backgroundColor:"red"}}>asdf</div> */}
        <Header hideAnnouncement={this.props.hideAnnouncement}/>
        {this.props.children}
        <Footer />
      </div>
    );
  }
}
