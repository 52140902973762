import React from 'react';
import TodoItem from '../TodoItem';

const MarketingList = () => {
  // const todos = props.todos || {};

  return (
    <div className="subList" id="marketing">
      <h2 className="title">Marketing</h2>
      {false && (
        <p className="subtitle">
          If a DPC practice opens in a forest but it has no patients, has it really opened?
        </p>
      )}
      <div className="items">
        <TodoItem id="marketing-mappers">
          <p className="title">Add your practice to the DPC Frontier mapper</p>
          <div className="description">
            <p>
              Go to{' '}
              <a target="_blank" rel="noopener noreferrer" href="https://dpcfrontier.com">
                dpcfrontier.com
              </a>
              , click "Physician Login" at the top right, create your account, and follow the
              instructions on screen to create your mapper listing. Fill out as much information as
              possible.
            </p>
          </div>
        </TodoItem>
        <TodoItem id="marketing-dpc-nation">
          <p className="title">Practice teaching people about DPC</p>
          <div className="description">
            <p>
              This is a make-or-break skill to have as a DPC doc. Get your 30 second description of
              DPC down pat. Iterate on your language and pay attention to what different types of
              patients find compelling.
            </p>
            <p>
              If a patient wants to learn more about DPC, direct them to{' '}
              <a target="_blank" rel="noopener noreferrer" href="https://dpcnation.org">
                DPC Nation
              </a>
              . DPC Nation is a patient-centric DPC educational website.
            </p>
          </div>
        </TodoItem>
        <TodoItem id="marketing-google">
          <p className="title">Create two Google My Business (GMB) profiles</p>
          <div className="description">
            <p>
              You can directly provide Google information about your business, and they'll show a
              special business infobox on searches by people in your area. It's easy: go to{' '}
              <a target="_blank" rel="noopener noreferrer" href="https://business.google.com">
                business.google.com
              </a>{' '}
              and fill out the form. You'll need a Google account.
            </p>
            <p>
              You should create <b>two profiles</b>. This is important. One profile should be for
              your business ("Awesome DPC"), the other should be for you as a physician ("Alex
              Awesomepants, MD"). This way, Google will present good information to a potential
              patient regardless of whether they search for you or your practice.
            </p>
            <p>
              Once you fill out the form, Google will send you a <b>physical postcard</b> with a
              verification code on it. They do this to ensure that you are a real business with a
              brick-and-mortar location that can receive mail. The postcard should arrive within 14
              days.
            </p>
          </div>
        </TodoItem>
        <TodoItem id="marketing-fb-page">
          <p className="title">Make a Facebook Page for your practice</p>
          <div className="description">
            And, yes, you'll have to actually post things to it. Preferably interesting ones. Some
            ideas:
            <br />
            <ul>
              <li>preventative health tips</li>
              <li>pithy comments about medically-related current events</li>
              <li>PSAs relating to common issues: tick bites, flu, etc</li>
              <li>
                interesting developments in the DPC world; your patients have a stake in DPC too
              </li>
            </ul>
          </div>
        </TodoItem>
        <TodoItem id="marketing-social-media">
          <p className="title">Claim your profiles on online review sites</p>
          <div className="description">
            <p>
              Chances are you have profiles on multiple major doctor review sites. Go claim your
              profiles and update your information! Your previous employer may have been managing
              these profiles on your behalf; if so, you'll need to contact them and ask them to
              unlock/unclaim your profile.
            </p>
            <p>
              Pay attention to major sites like{' '}
              <a target="_blank" rel="noopener noreferrer" href="https://yelp.com">
                Yelp
              </a>
              ,{' '}
              <a target="_blank" rel="noopener noreferrer" href="https://vitals.com">
                Vitals
              </a>
              ,{' '}
              <a target="_blank" rel="noopener noreferrer" href="https://ratemds.com">
                RateMDs
              </a>
              ,{' '}
              <a target="_blank" rel="noopener noreferrer" href="https://webmd.com">
                WebMD
              </a>
              , and{' '}
              <a target="_blank" rel="noopener noreferrer" href="https://healthgrades.com">
                Healthgrades
              </a>
              .
            </p>
          </div>
        </TodoItem>
        <TodoItem id="marketing-photos">
          <p className="title">Get professional photos taken</p>
          <div className="description">
            Pay someone to come and take professional photos of you and your office! Then post them
            to your Google profiles, Facebook page, social media accounts, review site profiles, and
            website.
          </div>
        </TodoItem>
        <TodoItem id="marketing-free-exposure">
          <p className="title">Brainstorm ways to get free exposure</p>
          <div className="description">
            <p>
              Make a list of ways to inform your community about your existence! Marketing is a
              numbers game; you've got to struggle along for a while before the seeds you've planted
              start to bear fruit. Don't be discouraged.
            </p>
            <p>
              Be clever and strategic! Think about where lots of people go and are! Think about what
              you can do to curry goodwill with important figures or widely known people in the
              community! Winning over one super-social connector could provide you with a permanent
              source of interested patients. Think about subpopulations that stand to benefit in an
              outsized way from DPC: the elderly, the children of the elderly, athletic groups,
              worried moms, uninsured students, marginalized populations, struggling employers,
              health nuts, [fill in the blank]. Make a list of tight-knit communities where DPC
              could spread like wildfire: country clubs, rotaries, bingo/poker clubs. Think of free
              services you could provide to get people in the door or start a conversation: flu
              shots, cheap blood tests. Propose an interview to local talk radio stations. Ask your
              patients what convinced them to join - you’ll start hearing certain phrasing over and
              over again. Use that phrase in your marketing.
            </p>
          </div>
        </TodoItem>
        <TodoItem id="marketing-logo">
          <p className="title">Get a logo</p>
          <div className="description">
            Find a local graphic designer, find someone on{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://upwork.com">
              Upwork
            </a>
            , or commission a logo design contest on{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://99designs.com">
              99designs
            </a>
            . Don't try to design your logo yourself, it'll be terrible. A great logo is well worth
            a few hundred bucks, even if that seems high at first blush. An amateurish logo can
            really hamper your legitimacy in the eyes of a patient.
          </div>
        </TodoItem>
        <TodoItem id="marketing-materials">
          <p className="title">Custom printed stationery and marketing materials</p>
          <div className="description">
            <p>
              Printing out promotional materials is a great way to put information about your
              practice out into the world. You never know who will see a flyer you send home with a
              patient. If you meet with an interested potential patient, give them a card or a
              brochure - they'll be more likely to get back to you if they have a physical reminder
              of your conversation. Put up signs around your community, especially if you're doing
              an open house/Q&A or offering a pre-enrollment deal.
            </p>
            <p>
              It's remarkably easy to design these things yourself once you have a good logo. Check
              out{' '}
              <a target="_blank" rel="noopener noreferrer" href="https://canva.com">
                Canva
              </a>
              —it's a slick online tool that lets you plug your logo and business information into a
              bunch of excellent pre-designed templates.
            </p>
            <ul>
              <li>Flyers/brochures: make it easy for your patients to advertise on your behalf.</li>

              <li>
                Business cards: make it easy for your patients to contact you; this is likely a huge
                factor in their decision to join a DPC practice
              </li>
              <li>
                Big posters: ask local businesses if you can put them up in the entryway. Also buy a
                sandwich board on Amazon{' '}
                <a target="_blank" rel="noopener noreferrer" href="https://amzn.to/2KHHOKm">
                  here
                </a>{' '}
                and put one on the sidewalk outside of your building
              </li>
              <li>Sign: get a big sign for the exterior of your building</li>
              <li>Envelopes and stationery</li>
            </ul>
            <br />
            <br />
            Custom letterhead, envelopes: not vital but a nice-to-have that lends additional
            professionalism to your practice.
          </div>
        </TodoItem>
        <TodoItem id="marketing-disseminate">
          <p className="title">Disseminate your marketing materials</p>
          <div className="description">
            <p>
              Put your cards/flyers at local gyms, day cares, YMCAs, universities, grocery stores,
              benefits consultants, and health establishments of any kind. Think about who in your
              community has the greatest need for DPC. Then try to figure out where those people go.
            </p>
          </div>
        </TodoItem>
        <TodoItem id="marketing-referrals">
          <p className="title">Ask for referrals</p>
          <div className="description">
            {' '}
            Go chat with urgent cares and specialists near you. Pitch them on the DPC model (and
            assure them it’s viable - they won’t send people to you if they don’t believe DPC will
            work). Often urgent cares will refer "trouble patients" to you. Reach out to Health
            Sharing Ministries - they often highlight DPC practices.
          </div>
        </TodoItem>
        <TodoItem id="marketing-retiring">
          <p className="title">Check for retiring physicians nearby</p>
          <div className="description">
            If they are retiring early as an escape from bureaucratic headaches, they may be
            interested in working as an employed physician at your practice.
            <br />
            <br />
            Otherwise, you might be able to convince them a) of the merits of DPC and b) to send
            their patients your way once they close their doors.
          </div>
        </TodoItem>
        <TodoItem id="marketing-network">
          <p className="title">Network with other small business owners</p>
          <div className="description">
            <p>
              Check out BNI, local{' '}
              <a target="_blank" rel="noopener noreferrer" href="https://meetup.com">
                Meetups
              </a>
              , the Chamber of Commerce, Rotary, 1 Million Cups, NFIB, and Facebook Groups for local
              business owners.
            </p>
          </div>
        </TodoItem>
        <TodoItem id="marketing-tpas-and-brokers">
          <p className="title">Reach out to nearby self-insurance TPAs/brokers</p>
          <div className="description">
            The DPC model perfectly complements the high-deductible, low-premium plans a
            self-insurance broker is likely to be selling. These brokers could bring an entire
            company of patients in one fell swoop.
          </div>
        </TodoItem>
        {/* <TodoItem id="marketing-mappers">
          <p className="title">
            Add your practice to the{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://mapper.dpcfrontier.com">
              DPC Frontier mapper
            </a>
          </p>
        </TodoItem> */}
        <TodoItem id="marketing-health-sharing-ministries">
          <p className="title">Reach out to Health Sharing Ministries</p>
          <div className="description">
            There are probably multiple based out of the nearest city to you. Make yourself known to
            them - they often highlight DPC practices.
          </div>
        </TodoItem>
        <TodoItem id="marketing-specialists">
          <p className="title">Talk to the specialists you refer to</p>
          <div className="description">
            Specialists tend to like DPC practices: they get paid in cash immediately by an entity
            with a face and an email address (that would be you). They may direct patients to your
            practice, or at least mention the existence of DPC to any particularly disgruntled
            patients.
          </div>
        </TodoItem>

        <TodoItem id="marketing-paid-advertising">
          <p className="title">Consider paying for advertising</p>
          <div className="description">
            <ul>
              <li>
                Print: radio, billboard, and newspaper ads. The consensus is these are poor
                investments.
              </li>
              <li>
                Facebook: you can "boost" a post on your Page to get it in the timelines of people
                in your area. It's not very targeted; your mileage may vary. Some people have found
                success with boosting, but the best way to get patients from Facebook is to
                regularly post useful, interesting content and encourage all your patients to join.
              </li>
              <li>
                Google Ads: it's easier than ever for small businesses to advertise on Google. You
                have a lot of control; you can specify exactly which search queries you'd like to
                show up for.
              </li>
            </ul>
          </div>
        </TodoItem>
        <TodoItem id="marketing-partnerships">
          <p className="title">Make advertising partnerships</p>
          <div className="description">
            Partner with local gyms, YMCA, and health clubs. You can each display the other's
            marketing materials, send each other customers, and even offer "exclusive partnership
            discounts" (!)
          </div>
        </TodoItem>
        <TodoItem id="marketing-referral-program">
          <p className="title">Set up a referral program</p>
          <div className="description">
            Offer a $100 Amazon gift card to patients that refer someone to your practice.
          </div>
        </TodoItem>
      </div>
    </div>
  );
};

export default MarketingList;
