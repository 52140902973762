import React from 'react';
import TodoItem from '../TodoItem';

const HipaaList = props => {
  // const todos = props.todos || {};

  return (
    <div className="subList" id="hipaa">
      <h2 className="title">HIPAA</h2>
      <p className="subtitle">
        If you're a pure direct primary care practice, you're probably done already{' '}
        <span role="img" aria-label="party">
          🎉
        </span>
      </p>
      <div className="items">
        <TodoItem id="hipaa-are-you-covered-entity">
          <p className="title">Determine if you are a covered entity under HIPAA</p>
          <div className="description">
            To answer this, check out DPC Frontier's thorough discussion of HIPAA{' '}
            <a target="_blank" rel="noopener noreferrer" href="http://www.dpcfrontier.com/hipaa">
              here
            </a>
            . If you are not covered, feel free to ignore the rest of this list.
          </div>
        </TodoItem>
        <TodoItem id="hipaa-state-laws">
          <p className="title">Check for state laws regarding patient privacy</p>
          <div className="description">In some states they are even more stringent than HIPAA.</div>
        </TodoItem>
        <TodoItem id="hipaa-risk-assessment">
          <p className="title">Complete a Security Risk Assessment</p>
          <div className="description">
            This{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://bit.ly/1WWwx62">
              free tool
            </a>{' '}
            from the ONC will make this much easier.
          </div>
        </TodoItem>
        <TodoItem id="hipaa-notice-of-privacy-policies">
          <p className="title">Draft a Notice of Privacy Policies (NPP)</p>
          <div className="description">
            The HHS publishes a{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://bit.ly/2XA8115">
              sample NPP
            </a>{' '}
            that can be easily customized for your practice.
            <br />
            <br />
            Once completed, publish the notice to your website.
          </div>
        </TodoItem>
        <TodoItem id="hipaa-authorization-form">
          <p className="title">Draft a Release of Records Authorization Form</p>
          <div className="description">
            HIPAA requires you to have a Release of Records Authorization form on file for any
            disclosure of protected health information for purposes other than treatment, payment,
            and health care. Here is{' '}
            <a target="_blank" rel="noopener noreferrer" href="/documents/release-of-records.doc">
              an example
            </a>
            .
            <br />
            <br />
            Every time you have a patient sign one of these, make a note in an Accounting of
            Disclosures log such as{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://bit.ly/2WPPpgJ">
              this
            </a>
            . You must be able to acocunt for all PHI discosures you've made should you get audited.
          </div>
        </TodoItem>
        <TodoItem id="hipaa-consent-form">
          <p className="title">Draft a Patient Consent Form</p>
          <div className="description">
            Though not required by HIPAA, many practices also have a Patient Consent Form which lets
            the patient green-light certain forms of communication (email, text, phone calls,
            answering machines). It also affords your practice an extra measure of protection. Here
            is a{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://bit.ly/2K4Fyh2">
              sample Consent Form
            </a>
            .
          </div>
        </TodoItem>
        <TodoItem id="hipaa-proof-of-compliance">
          <p className="title">Gather and maintain proof of HIPAA compliance</p>
          <div className="description">
            This is a multi-faceted problem; full compliance involves writing a Breach Plan, a
            Training Plan, a Communications Plan, a Disaster Recovery Plan, and an Audit and
            Monitoring Plan, plus the maintenance of a detailed Policies and Procedures Manual and
            data governance documentation. Check out{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://bit.ly/2qPCSZE"
              // href="https://www.ama-assn.org/practice-management/hipaa-compliance"
            >
              this page
            </a>{' '}
            from the AMA for some resources to get you started. You can find a lot of the Policies
            and Procedures online - check out Kim Corba's{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://dpcmanual.com/">
              DPC Manual
            </a>
            .
          </div>
        </TodoItem>
        <TodoItem id="hipaa-business-associates">
          <p className="title">Gather Business Associate Agreements</p>
          <div className="description">
            You need to get a signed Business Associate Agreement from every company/product/service
            that handles your patients' health info. Some notable exceptions are labs, specialists
            you refer to, and data "conduits" (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://personcenteredtech.com/vendorreview/signal/">
              some messaging services
            </a>{' '}
            qualify). See a complete description{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://bit.ly/2w6oaC4"
              // href="https://www.hhs.gov/hipaa/for-professionals/privacy/guidance/business-associates/index.html"
            >
              here
            </a>{' '}
            and an agreement template{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://bit.ly/2qkxhat"
              // href="https://www.hhs.gov/hipaa/for-professionals/covered-entities/sample-business-associate-agreement-provisions/index.html"
            >
              here
            </a>
            .{' '}
          </div>
        </TodoItem>
      </div>
    </div>
  );
};

export default HipaaList;
