import React from 'react';
import TodoItem from '../TodoItem';

const LegalList = props => {
  // const todos = props.todos || {};

  return (
    <div className="subList" id="incorporation">
      <h2 className="title">Incorporation</h2>
      <p className="subtitle">Choose the right entity structure for your practice</p>
      <div className="items">
        <TodoItem id="business-structure">
          <p className="title">Determine what business structure is best for your needs</p>
          <div className="description">
            <p>
              First, check out your state's guidelines on professional entities. Some states require
              you to operate as a professional entity (PLLC or PC) instead of a generic equivalent
              (LLC or Corporation). Here's a{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://bit.ly/2MDGydW">
                state-by-state rundown
              </a>
              .
            </p>
            <p>
              After figuring that out, decide whether an LLC or Corporation is right for you. Basic
              rule of thumb: if you want to add an additional physician/partner at some point a PC
              will be easier. If you know you'll stay a one-doc shop forever, a PLLC could make your
              life easier since it allows pass-through taxation. Basically all business revenue
              "passes through" the LLC to you and is treated by the IRS as personal income. This
              eliminates the need to do an independent corporate income tax return.
            </p>
            <p>
              You may likely want to confer with an accountant to make this decision, or look at
              other nearby practices.
            </p>
          </div>
        </TodoItem>
        <TodoItem id="business-articles">
          <p className="title">Draft Articles of Organization/Incorporation</p>
          <div className="description">
            This document serves to create a legal entity and provide basic information about it.
            <br />
            <br />
            Here is a{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://intuit.me/2MAAsLi">
              sample Articles of Organization
            </a>{' '}
            for an LLC.
            <br />
            <br />
            Here is a{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://bit.ly/2EW6hIw">
              sample Articles of Incorporation
            </a>{' '}
            for an generic corporation.
          </div>
        </TodoItem>
        <TodoItem id="business-operating-agreement">
          <p className="title">Draft an operating agreement/bylaws for the company</p>
          <div className="description">
            This document details the members' business interests and ownership shares, their rights
            and responsbilities as members, the allocation of profits and losses, and other
            protocols for managing and operating the business.
            <br />
            <br />
            Here is a{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://bit.ly/2XzEp3U">
              sample Operating Agreement
            </a>{' '}
            for an LLC.
            <br />
            <br />
            Here are some{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://bit.ly/2WV4ai7">
              sample bylaws
            </a>{' '}
            for an generic corporation.
            <br />
            <br />
            When actually drafting this agreement, you and all partners of the practice should meet
            with a small business lawyer.
          </div>
        </TodoItem>
        <TodoItem id="business-registered-agent">
          <p className="title">Find a registered agent</p>
          <div className="description">
            The role of a registered agent is explained in{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://bit.ly/2MCxvKt">
              this video
            </a>
            . It's almost{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://bit.ly/2r4Fq6a">
              always a good idea
            </a>
            .
          </div>
        </TodoItem>
        <TodoItem id="business-register-business">
          <p className="title">Incorporate the business through your state</p>
          <div className="description">
            This can usually be done online through your states business center - just Google
            "[state] business entity formation". You'll need your Articles of
            Incorporation/Organization.
          </div>
        </TodoItem>
        <TodoItem id="business-dba">
          <p className="title">File a DBA with your state</p>
          <div className="description">
            Unless you want to have "Awesome Direct Primary Care, PLLC" as your patient-facing
            brand, you should file a DBA (Doing Business As) form with your state. This allows you
            to legally operate under a different name ("Awesome DPC"). This form may also be called
            a Fictitious Business Name (FBN) form in some states. Google "how to file dba [your
            state]" for instructions.
          </div>
        </TodoItem>
        <TodoItem id="business-get-ein">
          <p className="title">Apply for your Employer Identification Number (EIN)</p>
          <div className="description">
            The EIN is like the Social Security number for your business. It's the number the IRS
            uses to identify you. You can apply for one online{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://bit.ly/1P1f7jS">
              here
            </a>{' '}
            - it's painless.
          </div>
        </TodoItem>
      </div>
    </div>
  );
};

export default LegalList;
