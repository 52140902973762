import { message } from 'antd';

export const handle = err => {
  let msg =
    (err.response && err.response.data && err.response.data.message) ||
    (err.response && err.response.data) ||
    err.message ||
    'Unknown error.';
  message.error(msg);
};
