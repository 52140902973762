import React from 'react';
import TodoItem from '../TodoItem';

const PracticeList = props => {
  // const todos = props.todos || {};

  return (
    <div className="subList" id="scope">
      <h2 className="title">Scope of practice</h2>
      <p className="subtitle">Decide what services to offer to your members</p>

      <div className="items">
        <TodoItem id="scope-expand-your-mind">
          <p className="title">Expand your mind</p>
          <div className="description">
            <p>
              Not everything has to be 100% covered by a membership (though of course, you should
              include as much as is feasible). Many practices offer additional services—procedures,
              OB, physicals, preventative health screens, etc—on a cash-pay fee-for-service basis.
              Consider what to include in the membership, what to provide at-cost, and what to
              charge extra for.
            </p>
            <p>
              As a DPC doctor, you're the linchpin of your patients' care. Watch{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.youtube.com/watch?v=kbLhTlSJSXA">
                this excellent talk
              </a>{' '}
              by Drs. Lassey and Tomsen on expanding your scope of practice. Some services are a
              great way to attract patients, others can be lucrative add-ons to your practice.
              Consider expanding your scope to include: Consider whether you'd be willing to offer
              the following services:
            </p>
          </div>
        </TodoItem>
        <TodoItem id="practice-in-house-dispensing">
          <p className="title">In-office dispensing</p>
          <div className="description">
            A lot of practices do this. In-office dispensing is a great perk to offer your patients:
            if saves them trips to the pharmacy and a lot of money besides. Some states also require
            licenses, others impose limitations, and other make it nearly impossible - see DPC
            Frontier's{' '}
            <a target="_blank" rel="noopener noreferrer" href="http://www.dpcfrontier.com/states">
              state-by-state legal analysis
            </a>{' '}
            for description.
            <br />
            <br />
            There may be additional administrative overheads associated with buying and tracking Rx
            inventory, but it doesn't have to be burdensome. You can purchase pre-packaged
            pharmaceuticals from wholesalers like{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://www.andameds.com/login.htm">
              AndaMeds
            </a>{' '}
            or{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://www.henryschein.com">
              Henry Schein
            </a>
            , and re-distribute them to patients in the original packaging. If you prefer more fine
            grained control, you may need to purchase a pill-counter and Rx inventory tracking
            software.
            <br />
            <br />
            Note that offering some of these services may raise your malpractice insurance premiums.
            Also, many states will require an inspection by the pharmacy board — be sure to look up
            your state's policy on that.
          </div>
        </TodoItem>
        <TodoItem id="scope-joint-injections">
          <p className="title">joint injections</p>
        </TodoItem>
        <TodoItem id="scope-lesion-removal">
          <p className="title">minor procedures</p>
          <div className="description">
            For instance: vasectomies, hemorrhoid excision, abscess I&D, and cryotherapy.
          </div>
        </TodoItem>
        <TodoItem id="scope-casting">
          <p className="title">casting</p>
        </TodoItem>
        <TodoItem id="scope-cosmetic">
          <p className="title">cosmetic procedures</p>
        </TodoItem>
        <TodoItem id="scope-omt">
          <p className="title">OMT</p>
        </TodoItem>
        <TodoItem id="scope-vaccines">
          <p className="title">vaccines</p>
        </TodoItem>
        <TodoItem id="scope-dot-physicals">
          <p className="title">immigration/DOT physicals</p>
        </TodoItem>
        <TodoItem id="scope-stress-vo2">
          <p className="title">stress/VO2 max testing</p>
        </TodoItem>
        <TodoItem id="scope-migraine">
          <p className="title">migraine treatments</p>
        </TodoItem>
        <TodoItem id="scope-bone-density">
          <p className="title">bone density testing</p>
        </TodoItem>
        <TodoItem id="scope-body-composition-analysis">
          <p className="title">body composition analysis</p>
        </TodoItem>
        <TodoItem id="scope-travel-medicine">
          <p className="title">travel medicine</p>
        </TodoItem>
        <TodoItem id="scope-phlebotomy">
          <p className="title">phlebotomy</p>
          <div className="description">
            It's often worth putting in the time to learn phlebotomy, especially if you're starting
            off as a one-person operation. Look for classes at local community colleges or ask a
            nurse to teach you. This will save your patients a ton of time waiting around in a lab.
          </div>
        </TodoItem>
        <TodoItem id="scope-cpap">
          <p className="title">CPAP/sleep testing</p>
        </TodoItem>
        <TodoItem id="scope-hospital-coord">
          <p className="title">coordination of hospital care</p>
          <div className="description">
            Patients will be eternally grateful to have someone truly in their corner during a
            medical emergency. If you want to do this, you should start researching the
            credentialing processes at nearby hospitals.
          </div>
        </TodoItem>
        <TodoItem id="scope-obstetrics">
          <p className="title">obstetrics</p>
          <div className="description">Yes, some DPC practices do this!</div>
        </TodoItem>
      </div>
    </div>
  );
};

export default PracticeList;
