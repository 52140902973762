import React, { Component } from 'react';
import { Modal, Button } from 'antd';

import store from '../store';
import { pdfModalDuck, trelloModalDuck, saveProgressModalDuck } from '../ducks/root';

export default class FirstCheckModal extends Component {
  state = { show: true };

  componentWillUnmount() {
    this.setState({ show: false });
  }

  render() {
    return (
      <Modal
        title="Saving Your Progress"
        visible={this.state.show}
        okText={'Get PDF'}
        footer={
          <Button
            key="back"
            onClick={() => {
              store.dispatch(saveProgressModalDuck.clear());
            }}>
            Cancel
          </Button>
        }>
        <p className="mb16">
          {`Congrats on starting your DPC journey! 🎉 A journey of a thousand miles begins with a single checkbox! `}
        </p>
        <p className="mb16">
          {`This website stores your checklist progress temporarily, but if you clear your browser data, then your progress is lost. Instead, we propose one of these other options for tracking your progress:`}
        </p>
        {false && (
          <p className="mb16">
            Fortunately, there are some more reliable ways to track your progress!
          </p>
        )}
        {false && (
          <p className="mb16">
            {`If you prefer, you can get a PDF copy of the checklist and track your progress on
              good, ole-fashioned paper! Click "Get PDF" below to get the paper version.`}
          </p>
        )}

        <ol className="mb16 ml32">
          <li className="mt16 mb8" key="1">
            <b>Asana</b>. We've added the contents of this checklist into{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://asana.com">
              Asana
            </a>
            . If you haven't used Asana, it's an excellent task management tool that lets you track
            your progress, collaborate with others, upload files, delegate tasks, set due dates, and
            more. We strongly recommend using it to manage your DPC startup journey! Just click the
            button below to get access.
          </li>
          ,
          <Button
            key="2"
            type="primary"
            onClick={() => {
              store.dispatch(trelloModalDuck.set(true));
            }}>
            Request Asana Access
          </Button>
          <li className="mt16 mb8">
            <b>Paper</b>.{' '}
            {`If you prefer, you can get a PDF copy of the checklist and track your progress on
              good, ole-fashioned paper! Click "Get PDF" below to get the paper version.`}
          </li>
          <Button
            type="primary"
            onClick={() => {
              store.dispatch(pdfModalDuck.set(true));
            }}>
            Get PDF
          </Button>
        </ol>
      </Modal>
    );
  }
}
