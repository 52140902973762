import React from 'react';
import { Icon } from 'antd';

export default () => (
  <div className="section" style={{ backgroundColor: '#000000e0' }}>
    <div className="sectioncontent  p64 col ac jc inherit">
      <div className="wide row js as wrap wh70">
        <div className="col js as mh16 mt16 mw125 fl1">
          <span className="small wh30 mb8">Contact</span>
          <a target="_blank" href="mailto:team@bagel.md" className="mb8">
            <Icon type="mail" /> Email us
          </a>
          {false && (
            <a target="_blank" href="/schedule" className="mb8">
              <Icon type="calendar" /> Schedule a conversation
            </a>
          )}
          <span>
            <a target="_blank" href="https://twitter.com/bagelmd" className="clickable">
              <Icon type="twitter" style={{ fontSize: '20px', marginRight: '8px' }} />
            </a>
            <a target="_blank" href="https://www.facebook.com/groups/bagelmd" className="clickable">
              <Icon
                type="facebook"
                theme="filled"
                style={{ fontSize: '20px', marginRight: '8px' }}
              />
            </a>
            <a target="_blank" href="https://instagram.com/bagel.md/" className="clickable">
              <Icon type="instagram" style={{ fontSize: '20px', marginRight: '8px' }} />
            </a>
          </span>
        </div>
        <div className="col js as mh16 mt16 mw125 fl1">
          <span className="small wh30 mb8">Learn about DPC</span>

          <a target="_blank" href="https://dpcnation.org" className="clickable">
            What is DPC?
          </a>
          <a target="_blank" href="https://mapper.dpcfrontier.com" className="clickable">
            Find a practice
          </a>
          <a target="_blank" href="https://dpcfrontier.com" className="clickable">
            DPC Frontier
          </a>
        </div>
        <div className="col js as mh16 mt16 mw125 fl1">
          <span className="small wh30 mb8">Legal</span>
          <a target="_blank" href="/terms">
            Terms
          </a>
          <a target="_blank" href="/privacy">
            Privacy
          </a>
          <a target="_blank" href="/compliance">
            Compliance
          </a>
          <a target="_blank" href="/security">
            Security
          </a>
        </div>
        <div className="col je as mh16 mt16 mw125 fl1">
          <img
            src="/img/white_wordmark.png"
            style={{ width: '100px' }}
            alt="White Bagel Logo"
            title="White Bagel Logo"
          />
          <span className="wh30">©2019 Bagel Health, Inc.</span>
        </div>
      </div>
    </div>
  </div>
);
