import React from 'react';
import TodoItem from '../TodoItem';

const ValidationList = props => {
  // const todos = props.todos || {};

  return (
    <div className="subList" id="validation">
      <h2 className="title">Viability</h2>
      <p className="subtitle">Make sure your DPC practice is feasible</p>
      <div className="items">
        <TodoItem id="validate-other-options">
          <p className="title">Consider working as an employed DPC physician</p>
          <div className="description">
            DPC is at the point where many early practices are looking to grow to multiple
            physicians. If you're not cut out to run a small business, consider working as an
            employed physician in a DPC practice near you. You'll still get all the benefits of DPC
            (small patient panel, less administrative BS, etc) without the headaches of starting
            your own practice.
            <br />
            <br />
            Check out DPC Frontier's{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.dpcfrontier.com/career-opportunities/">
              Careers page
            </a>{' '}
            or{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://iamdirectcare.com/direct-careers/">
              iamdirectcare.com
            </a>{' '}
            to find job listings from existing DPC clinics. Or reach out directly to the DPC
            practices in your area (find them with the DPC Frontier{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://mapper.dpcfrontier.com">
              mapper
            </a>
            ).
          </div>
        </TodoItem>
        <TodoItem id="validate-legal-hurdles">
          <p className="title">
            Check if there are any legal hurdles to DPC practices in your state
          </p>
          <div className="description">
            This information has been compiled on DPC Frontier{' '}
            <a target="_blank" rel="noopener noreferrer" href="http://www.dpcfrontier.com/states/">
              here
            </a>
            .
          </div>
        </TodoItem>
        <TodoItem id="validate-initial-patients">
          <p className="title">Do you have a means of acquiring patients initially?</p>
          <div className="description">
            Are you known in your community? Are you migrating an existing non-DPC practice? Or if
            you're starting from scratch, can you take some patients with you?
            <br />
            <br />
            Needless to say, a way this isn't absolutely necessary, but it will makes things easier
            on you (financially and psychologically).
            <br />
            <br />A note on non-compete clauses: they are difficult to enforce in many states (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.dpcfrontier.com/blog/2016/7/3/can-it-be-enforced">
              source
            </a>
            ) so don't be too intimidated. Check out the appendices of{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://bit.ly/2XyOiix"
              // href="http://www.bassberry.com/~/media/Files/Publications/2013/05/AHLA_Article_Horton_and_Padgett_April_2013.pdf"
            >
              this guide
            </a>{' '}
            to see your state's statutes on physican non-competes. Also, don't be afraid to simply
            ask your employer to waive the non-compete clause.
          </div>
        </TodoItem>
        <TodoItem id="validate-survey">
          <p className="title">If considering a transition, run the idea by your patients</p>
          <div className="description">
            Direct them to{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://dpcnation.org">
              to learn more
            </a>{' '}
            or use{' '}
            <a target="_blank" rel="noopener noreferrer" href="/documents/dpc-interest-survey.pdf">
              this survey
            </a>{' '}
            from{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://iwantdirectcare.org">
              iwantdirectcare.org
            </a>{' '}
            as a reference for the conversation.
          </div>
        </TodoItem>
        <TodoItem id="validate-likable">
          <p className="title">Are you likeable?</p>
          <div className="description">
            Sounds silly, but this can make or break a practice, especially in DPC. Most DPC
            practices rely heavily on word-of-mouth evangelism. Any new patient has to be convinced
            both of the DPC model and of you. So smile{' '}
            <span role="img" aria-label="smile">
              🤗
            </span>
          </div>
        </TodoItem>
      </div>
    </div>
  );
};

export default ValidationList;
