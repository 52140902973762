import React from 'react';
import TodoItem from '../TodoItem';

const CliaList = props => {
  // const todos = props.todos || {};

  return (
    <div className="subList" id="clia">
      <h2 className="title">CLIA</h2>
      <p className="subtitle">AKA how to avoid the need for CLIA compliance</p>
      <div className="items">
        <TodoItem id="clia-whether-to-do-tests">
          <p className="title">Decide whether to do any in-office testing</p>
          <div className="description">
            Many commonly performed in-office labs can now be performed by the patients themselves
            using home testing kits. This is includes HIV, Hep C, Strep, and UTI diagnostics, as
            well as lipid panels, TSH, HgA1c, PSA, and Vitamin D tests. See a more complete list of
            offerings{' '}
            <a target="_blank" rel="noopener noreferrer" href="http://www.testkitsathome.com/">
              here
            </a>
            .
            <br />
            <br />
            Though home testing is an increasingly good option, these kits can be frustrating and
            time-consuming for patients, and home kits don't exist for all CLIA-waived tests (see{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://go.cms.gov/23QRHaE">
              full list
            </a>
            ).
          </div>
        </TodoItem>
        <TodoItem id="clia-cert-of-waiver">
          <p className="title">To do CLIA-waived tests: file for a waiver</p>
          <div className="description">
            To get waived, fill out a{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://go.cms.gov/2Iilq63">
              CMS-116 form
            </a>{' '}
            and submit it to your state's CLIA Agency (lookup{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://go.cms.gov/2KBMUIo">
              here
            </a>
            ). Some time later, you'll receive a "remittance coupon" detailing how to pay the
            certificate fee.
            <br />
            <br />
            As of 2014, the following states have additional requirements surrounding
            physician-office lab tests: AZ, CA, CT, DC, FL, LA, ME, MD, MA, MI, NV, NJ, OR, PA, WA,
            and Puerto Rico. Do a Google search to figure out what else you have to do.
            <br />
            <br />
            <b>Washington State residents</b>: you should fill out{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://bit.ly/2WsCzFE">
              this form
            </a>{' '}
            in place of the CMS-116 and mail it to the listed address.
          </div>
        </TodoItem>
        <TodoItem id="clia-ppm">
          <p className="title">Consider offering physician-performed microscopy (PPM) services</p>
          <div className="description">
            PPM is the next level up from waived status. It lets you do all waived tests as well as{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://bit.ly/31jn7KL">
              these microscopic procedures
            </a>
            . However, this requires compliance with multiple subparts of CLIA regulation (Patient
            Test Management, Quality Control, Personnel, Quality Assurance, and to some extent
            Proficiency Testing) and is probably not worth the trouble, especially early on.
          </div>
        </TodoItem>
        <TodoItem id="clia-best-practices">
          <p className="title">Maintain best practices</p>
          <div className="description">
            Check out this{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://bit.ly/2WrMWEK">
              strangely colorful document
            </a>{' '}
            from the CDC detailing best practices for CLIA-waived facilities. How much of this you
            decide to implement depends mostly on your risk tolerance - any CLIA-waived facility is
            subject to random audits, though they are rare.
          </div>
        </TodoItem>
        <TodoItem id="clia-renewal-reminder">
          <p className="title">Set up a reminder to renew your waiver every two years</p>
          <div className="description">
            <a target="_blank" rel="noopener noreferrer" href="https://www.followupthen.com">
              FollowUpThen
            </a>{' '}
            is a great free service for scheduling email reminders.
          </div>
        </TodoItem>
      </div>
    </div>
  );
};

export default CliaList;
