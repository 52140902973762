export const isLocal = false;
// typeof window !== 'undefined' ? window.location.host.includes('localhost') : false;

export const rootURL = isLocal ? `http://localhost:8000` : `https://bagel.md`;
export const authDomain = `auth.bagel.md`;
export const authClientId = `Y1-JsW0rhnUCh3lalyfEf7ZPLYkAQkuk`;
export const callbackURL = `${rootURL}/todo`;
export const apiId = isLocal ? 'internal_bagel_backend_testing' : 'internal_bagel_backend';
export const serverURL = isLocal
  ? //  ? 'http://localhost:4000/dev'
    'http://localhost:5000/bagelweb/us-central1'
  : 'https://us-central1-bagelweb.cloudfunctions.net';
export const wsURL = isLocal ? 'ws://localhost:4000/dev' : 'wss://api.bagel.md/prod';
console.log(`serverURL: ${serverURL}`);
